import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SlideService } from 'app/shared/services/slide.service';
import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import { takeUntil } from 'rxjs/operators';
import { PlayerService } from 'app/pages/player/player.service';
import { IUserInfo, IUserProfileInfo, StorageKeys, UserDataHandlerService, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent extends UnsubscribeComponent implements OnInit {
  @Input() buttonEvent: Subject<boolean>;
  @Input() enableForward: boolean;
  @Input() enableBack: boolean;
  @Input() disabled: boolean;
  @Input() showAgreements: boolean;
  @Input() isFinish: boolean = false;
  @Input() showStartDiagnosticButton: boolean = false;

  public isBvbTest: boolean = false;
  public isBvbTestVisuallyImpaired: boolean = false;
  public userRole: string = '';
  public isValidField: boolean = false;
  private currentSlideNumber: number;

  constructor(
    private location: Location,
    private slideService: SlideService,
    private playerService: PlayerService,
    private changeDetectorRef: ChangeDetectorRef,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();

    const userInfo: IUserInfo = this.userDataHandlerService.getUserInfo();
    this.userRole = userInfo ? userInfo.role : this.webStorageService.get(StorageKeys.UserRole);

    this.isBvbTestVisuallyImpaired = this.webStorageService.get(StorageKeys.TestType) === ETestTypes.BVB_TEST_VISUALLY_IMPAIRED.toString();
    this.isBvbTest = this.webStorageService.get(StorageKeys.TestType) === ETestTypes.BVB_TEST.toString();
  }

  public ngOnInit(): void {
    this.slideService.getSlideNumber().subscribe(slide => {
      this.currentSlideNumber = slide;
    });

    this.playerService.isValidOpenAnswerSubject.pipe(takeUntil(this.unsubscribe)).subscribe(isValid => {
      if (this.isValidField !== isValid) {
        this.isValidField = isValid;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public back(): void {
    this.buttonEvent.next(false);
    this.slideService.changeSlideNumber(this.currentSlideNumber - 1);
  }

  public forward(): void {
    this.buttonEvent.next(true);
    this.slideService.changeSlideNumber(this.currentSlideNumber + 1);
  }

  public getLink(url): string {
    return document.location.origin + this.location.prepareExternalUrl(url);
  }
}
