import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { PortfolioAddModalService } from './portfolio-add-modal/portfolio-add-modal.service';

import { Gender } from 'app/shared/enums/gender.enums';
import { ApiProfilesService, IUserInfo, IUserProfileInfo, StorageKeys, UserDataHandlerService, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.scss'],
})
export class PortfolioPageComponent implements OnInit, OnDestroy {
  @Input()
  set child(val: any) {
    if (this.userInfo) {
      this.userInfo.userId = val.userId;
    }
    this.userGender = val.gender;
    this.isChild = true;
  }

  @Input()
  set childPortfolios(val: any) {
    this.portfolios = val;
  }

  @Output() _childPortfolios = new EventEmitter();

  public loaded: boolean = false;
  public isChild: boolean = false;
  public portfolios: any;
  public userGender: string = '';
  public userInfo: IUserInfo;

  private ngUnsubscribe = new Subject<any>();

  constructor(
    private meta: Meta,
    private apiProfilesService: ApiProfilesService,
    private portfolioAddModalService: PortfolioAddModalService,
    private webStorage: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Портфолио' });
  }

  ngOnInit() {
    // Если портфоли вызвано не из админки/учителя
    // То берем userID, userGender из стора

    if (!this.isChild) {
      this.userGender = this.webStorage.get(StorageKeys.UserGender);
    }
    this.userInfo = this.userDataHandlerService.getUserInfo();

    this.portfolioAddModalService.added$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(file => {
      if (file && file.id) {
        this.loadData();
      }
    });

    this.loadData();
    //  this.loaded = true;
  }

  public showModal() {
    this.portfolioAddModalService.show();
  }

  public isMale(gender: string) {
    return gender === Gender.Male;
  }

  private loadData() {
    this.apiProfilesService
      .getPortfolio(this.userInfo.userId)
      .pipe(take(1))
      .subscribe(response => {
        this.portfolios = response;
        if (this.isChild) {
          this._childPortfolios.emit(this.portfolios);
        }
        this.portfolioAddModalService.portfolioId = this.portfolios.id;

        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.portfolioAddModalService.hide();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
