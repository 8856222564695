import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { switchMap, takeUntil } from 'rxjs/operators';
import { of, Subscription, take, timer } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { CodeInputStateEnum } from '@profilum-components/code-input/code-input.config';
import { CodeInputComponent } from '@profilum-components/code-input/code-input.component';
import { ButtonType } from '@profilum-collections/common.collections';
import {
  AuthHandlerService,
  B2gEurochemService,
  EiType,
  Helper,
  ICheckReferralResponse,
  ICodeSendResponse,
  ICodeVerifyResponse,
  IRegisterBody,
  IRegisterResponse,
  StorageKeys,
  UtilsService,
  WebStorageService,
} from '@profilum-library';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { FormGeneratorComponent } from 'app/shared/common-components/form-generator/form-generator.component';
import { EurochemService } from 'app/shared/services/eurochem.service';
import { EUROCHEM_DICTIONARY } from '../dictionary';
import { SendCodeErrorsEnum } from '../eurochem.model';

@Component({
  selector: 'prf-registration-child',
  templateUrl: './registration-child.component.html',
  styleUrl: './registration-child.component.scss',
  providers: [B2gEurochemService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationChildComponent extends UnsubscribeComponent implements OnInit {
  public guId: string;
  public codeInputFieldState: CodeInputStateEnum = CodeInputStateEnum.DEFAULT;
  public formConfig = 'registration-child.form.json';
  public confirmButtonText: string = 'Получить код';
  public form: FormGroup;
  public cities: any[] = [];
  public eis: any[] = [];
  public eiType: EiType = EiType.School;
  public isParent: boolean;

  public isVisibleCode: boolean = false;
  public isPhoneUsed: boolean = false;
  public isReferralValid: boolean;
  public isEiEnabled: boolean = false;

  private timerSubscription: Subscription;
  private NEXT_ATTEMPT_SECONDS: number = 180;
  public secondsToNextCallRequest: number = this.NEXT_ATTEMPT_SECONDS;

  @ViewChild('dropdownCityRef') public dropdownCityRef: NgSelectComponent;
  @ViewChild('dropdownEiRef') public dropdownEiRef: NgSelectComponent;
  @ViewChild('pageBody') public pageBody: ElementRef;
  @ViewChild('childRegistrationForm') public childRegistrationForm: FormGeneratorComponent;
  @ViewChild('codeInput') public codeInput!: CodeInputComponent;

  private b2gEurochemService = inject(B2gEurochemService);
  private authHandlerService = inject(AuthHandlerService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private eurochemService = inject(EurochemService);
  private webStorageService = inject(WebStorageService);
  private formBuilder = inject(FormBuilder);
  private utilsService = inject(UtilsService);

  constructor(private route: ActivatedRoute) {
    super();
  }

  public ngOnInit() {
    this.listenToOrientationChange();
    this.route.queryParams.subscribe(params => {
      this.isParent = params['isParent'];
    });

    this.route.paramMap
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(params => {
          this.guId = params.get('hrid');

          return this.eurochemService.checkReferral(this.guId, false);
        }),
        switchMap((response: ICheckReferralResponse) => {
          this.isReferralValid = !(response.status !== 'Success' || !response.isValid);

          return this.eurochemService.getCities();
        }),
      )
      .subscribe((cities: string[]) => {
        cities.forEach((city, index) => this.cities.push({ id: index, value: city }));

        this.createForm();
        this.changeDetectorRef.detectChanges();
      });
  }

  public sendCode(): void {
    this.isPhoneUsed = false;
    const phone = this.convertPhoneNumber(this.childRegistrationForm.formValue.phone);

    this.authHandlerService
      .checkPhoneAvailability(phone)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(response => {
          if (response.free) {
            return this.b2gEurochemService.sendCode(this.guId, this.childRegistrationForm.formValue.phone);
          } else {
            this.isPhoneUsed = true;
            this.changeDetectorRef.detectChanges();

            return of(null);
          }
        }),
      )
      .subscribe((response: ICodeSendResponse) => {
        if (response?.status === 'Success') {
          this.isVisibleCode = true;
          this.webStorageService.clear(StorageKeys.ParentConsent);
          this.startPhoneCallTimer();
        }
      });
  }

  public verifyInputCode(code: string): void {
    this.b2gEurochemService
      .verifyCode(this.guId, code)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response: ICodeVerifyResponse) => {
          if (response.status === 'Failed') {
            this.codeInput.reset();
            this.codeInputFieldState = CodeInputStateEnum.ERROR;
            this.changeDetectorRef.detectChanges();
          } else if (response.status === 'Success') {
            this.timerSubscription.unsubscribe();
            this.registerChild();
          }
        },
        error: (): void => {
          this.codeInput.reset();
          this.codeInputFieldState = CodeInputStateEnum.ERROR;
        },
      });
  }

  public onSelectCity(event): void {
    this.form.patchValue({ city: event, eiName: '', other: '' });
    this.dropdownCityRef.placeholder = '';

    this.eurochemService.getEis(event, this.eiType).subscribe(
      (eis: string[]) => {
        this.setNewEis(eis);
        this.isEiEnabled = true;

        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.eis = [];
        this.utilsService.openSnackBar(
          this.eiType !== EiType.School ? 'В указанном городе образовательных центров не найдено' : 'В указанном городе школ не найдено',
          'error',
          3000,
        );
      },
    );
  }

  public onSelectEi(event): void {
    this.form.patchValue({ ei: event });
    this.dropdownEiRef.placeholder = '';
  }

  public onClick(): void {
    this.changeEiType();
    this.onSelectCity(this.form.value.city);
  }

  public onOpen(): void {
    this.pageBody.nativeElement.className = 'prf-login prf-login-fixed';
  }

  public onClose(): void {
    this.pageBody.nativeElement.className = 'prf-login';
  }

  private registerChild(): void {
    const registrationData: IRegisterBody = { ...this.form.value, ...this.childRegistrationForm.formValue };

    this.b2gEurochemService
      .register(this.guId, registrationData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: IRegisterResponse) => {
        if (response.status === 'Success') {
          const login: string = this.convertPhoneNumber(this.childRegistrationForm.formValue.phone);

          this.authHandlerService.login(login, this.childRegistrationForm.formValue.password);
        }
      });
  }

  private startPhoneCallTimer(): void {
    this.timerSubscription = timer(0, 1000)
      .pipe(take(this.NEXT_ATTEMPT_SECONDS), takeUntil(this.unsubscribe))
      .subscribe(passedSeconds => {
        this.secondsToNextCallRequest = (this.NEXT_ATTEMPT_SECONDS - passedSeconds - 1) * 1000;
        this.changeDetectorRef.detectChanges();
      });
  }

  private convertPhoneNumber(phoneNumber: string): string {
    if (REG_EXP.phoneRegExp.test(phoneNumber)) {
      phoneNumber = phoneNumber?.replace(/\D/g, '');
      if (phoneNumber) {
        phoneNumber = Helper.phoneValidation(phoneNumber);
      }
    }
    return phoneNumber;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      city: ['', [Validators.required]],
      eiType: [EiType.School, [Validators.required]],
      eiName: [''],
      other: [''],
    });
  }

  private setNewEis(eis: string[]) {
    this.eis = eis.map((ei, index) => {
      return { id: index, value: ei };
    });
  }

  private changeEiType(): void {
    this.eiType = this.eiType === EiType.School ? EiType.EducationCenter : EiType.School;
    this.form.patchValue({ eiName: '', other: '' });
  }

  private listenToOrientationChange(): void {
    screen.orientation.addEventListener('change', () => {
      this.dropdownCityRef.close();
      this.dropdownEiRef.close();
    });
  }

  protected readonly EUROCHEM_DICTIONARY = EUROCHEM_DICTIONARY;
  protected readonly SendCodeErrorsEnum = SendCodeErrorsEnum;
  protected readonly ButtonType = ButtonType;
  protected readonly CodeInputStateEnum = CodeInputStateEnum;
}
