import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PlayerService } from 'app/pages/player/player.service';
import { ISlideInterface } from 'app/pages/player/slide/slide.interface';
import { ISlideViewInterface } from 'app/shared/interfaces/islideview.interface';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IsAnonymousService } from '../../ui-kit/services/utils-services/is-anonymous.service';
import { ApiPlayerService } from '@profilum-library';

@Injectable()
export class SlideService {
  private sessions = {};
  private currentSlideNumber = new BehaviorSubject(null);

  constructor(
    public route: ActivatedRoute,
    private playerService: PlayerService,
    private isAnonymousService: IsAnonymousService,
    private apiPlayerService: ApiPlayerService,
  ) {}

  public setCurrentSlide(session, slideData): void {
    this.sessions[session] = slideData;
  }

  public getCurrentSlide(session): ISlideViewInterface {
    return this.sessions[session];
  }

  public getCurrenSlideNumber(sectionId, sessionId): number {
    const slide: ISlideViewInterface = this.getCurrentSlide(sessionId);
    if (slide.slide.slideView.bubbleView == null) {
      return slide.slide.orderNumber;
    }

    const sectionNumber: number = slide.slide.slideView.bubbleView.sections.findIndex(s => s.sectionId == sectionId);
    return sectionNumber + slide.slide.orderNumber;
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const resolver = new Subject();
    const session = route.params.ssid;
    const isAnonymous: boolean = this.isAnonymousService.isAnonymous();
    if (this.sessions[session] === undefined) {
      this.apiPlayerService
        .getCurrentSlide(session)
        .pipe(
          switchMap((slideInfo: ISlideInterface) => {
            if (!slideInfo?.slide) {
              return this.apiPlayerService.getNextSlide(session, isAnonymous);
            }
            return of(slideInfo);
          }),
        )
        .subscribe((slideInfo: ISlideInterface) => {
          this.sessions[session] = slideInfo;
          resolver.next(slideInfo);
          resolver.complete();
        });
    } else {
      setTimeout(() => {
        resolver.next(this.sessions[session]);
        resolver.complete();
      });
    }
    return resolver;
  }

  public changeSlideNumber(n: number): void {
    this.currentSlideNumber.next(n);
  }

  public getSlideNumber(): Observable<any> {
    return this.currentSlideNumber.asObservable();
  }
}
