import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PupilsTableComponent } from './pupils-table.component';
import { ChangePupilPhonePopupComponent } from '../change-pupil-phone-popup/change-pupil-phone-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from '@matheo/text-mask';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskModule } from 'app/shared/directives/phone-mask/phone-mask.module';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import { TippyDirective } from '@ngneat/helipopper';

@NgModule({
  declarations: [PupilsTableComponent, ChangePupilPhonePopupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TextMaskModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    PhoneMaskModule,
    TooltipComponent,
    TippyDirective,
  ],
  exports: [PupilsTableComponent, ChangePupilPhonePopupComponent],
})
export class PupilsTableModule {}
