import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ProfessionFilterPipe } from '../dashboard/professions/profession-filter/profession-filter.pipe';
import { CurrencyConvertPipe } from './currencyPipe';
import { EventsCurrencyPipe } from './events-currencyPipe';
import { FilterByNamePipe } from './filter-by-name.pipe';
import { FilterCoursesPipe } from './filter-courses.pipe';
import { FilterSchoolsPipe } from './filter-schools.pipe';
import { FirstUpperCasePipe } from './first-upper-case.pipe';
import { LowercaseFirstPipe } from './lowercase-first.pipe';
import { NumeralsPipe } from './numerals.pipe';
import { OrderByPipe } from './order-by.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SalaryPipe } from './salary.pipe';
import { TagFilterPipe } from './tag-filter.pipe';
import { TranslateByDefaultPipe } from './translate-by-default.pipe';
import { TrimPipe } from './trim.pipe';

@NgModule({
  imports: [RouterModule],
  declarations: [
    CurrencyConvertPipe,
    EventsCurrencyPipe,
    TagFilterPipe,
    SalaryPipe,
    SafeUrlPipe,
    OrderByPipe,
    NumeralsPipe,
    LowercaseFirstPipe,
    TrimPipe,
    FilterByNamePipe,
    FilterSchoolsPipe,
    ProfessionFilterPipe,
    TranslateByDefaultPipe,
    FirstUpperCasePipe,
    FilterCoursesPipe,
  ],
  exports: [
    CurrencyConvertPipe,
    EventsCurrencyPipe,
    TagFilterPipe,
    SalaryPipe,
    SafeUrlPipe,
    OrderByPipe,
    NumeralsPipe,
    LowercaseFirstPipe,
    TrimPipe,
    FilterByNamePipe,
    FilterSchoolsPipe,
    ProfessionFilterPipe,
    TranslateByDefaultPipe,
    FirstUpperCasePipe,
    FilterCoursesPipe,
  ],
  providers: [
    CurrencyConvertPipe,
    TagFilterPipe,
    SalaryPipe,
    SafeUrlPipe,
    OrderByPipe,
    NumeralsPipe,
    LowercaseFirstPipe,
    TrimPipe,
    FilterByNamePipe,
    FilterSchoolsPipe,
    FirstUpperCasePipe,
    TranslateByDefaultPipe,
    FilterCoursesPipe,
  ],
})
export class PipesModule {}
