import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChoicesProfessionsComponent } from 'app/shared/common-components/choices-professions/choices-professions.component';

@NgModule({
  declarations: [ChoicesProfessionsComponent],
  imports: [CommonModule],
  exports: [ChoicesProfessionsComponent],
})
export class ChoicesProfessionsModule {}
