import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClassCoursesComponent } from 'app/shared/common-components/class-courses/class-courses.component';
import { ClassCoursesLessonsComponent } from 'app/shared/common-components/class-courses/class-courses-lessons/class-courses-lessons.component';
import { RouterModule } from '@angular/router';
import { ClassCoursesChaptersComponent } from 'app/shared/common-components/class-courses/class-courses-chapters/class-courses-chapters.component';
import { YmReachGoalModule } from 'app/shared/directives/ym-reach-goal/ym-reach-goal.module';
import { TeacherMaterialsModalComponent } from './teacher-lessons/teacher-materials-modal/teacher-materials-modal.component';
import { CourseMaterialsModule } from '../course-materials/course-materials.module';

@NgModule({
  declarations: [ClassCoursesComponent, ClassCoursesChaptersComponent, ClassCoursesLessonsComponent, TeacherMaterialsModalComponent],
  imports: [CommonModule, SharedModule, RouterModule, YmReachGoalModule, CourseMaterialsModule],
  exports: [ClassCoursesComponent, ClassCoursesChaptersComponent, ClassCoursesLessonsComponent, TeacherMaterialsModalComponent],
})
export class ClassCoursesModule {}
