import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { StorageKeys, IProfession, WebStorageService, ApiProfessionsService, ProfessionsService, EmptyGuid } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

@Component({
  selector: 'prf-similar-professions',
  templateUrl: './similar-professions.component.html',
  styleUrls: ['./similar-professions.component.scss'],
})
export class SimilarProfessionsComponent implements OnInit {
  profession: IProfession;
  similarJobs: IProfession[];
  dataFetched: boolean = false;
  public userRole: string = '';

  constructor(
    private professionService: ProfessionsService,
    private overlayService: OverlayBusyService,
    private router: Router,
    private webStorageService: WebStorageService,
    private apiProfessionsService: ApiProfessionsService,
  ) {
    this.userRole = this.webStorageService.get(StorageKeys.UserRole);
  }

  ngOnInit() {
    this.professionService.getCurrentProfession().subscribe(job => {
      if (job) {
        this.profession = job;
        this.overlayService.show();

        // ToDo: проверить наличие поля fieldNames и изменялось ли оно
        if (this.profession.fieldNames) {
          const arr = this.createParams(this.profession.fieldNames);
          this.profession.fieldNames.forEach((field, i) => this.getJob(arr[i]));
        }
      }
    });
  }

  private createParams(fields) {
    let result = [];

    switch (fields.length) {
      case 1:
        result = [{ field: fields[0], from: 0, to: 3 }];
        break;
      case 2:
        result = [
          { field: fields[0], from: 0, to: 2 },
          { field: fields[1], from: 0, to: 1 },
        ];
        break;
      case 3:
        result = [
          { field: fields[0], from: 0, to: 1 },
          { field: fields[1], from: 0, to: 1 },
          { field: fields[2], from: 0, to: 1 },
        ];
        break;
    }
    return result;
  }

  private getJob(params) {
    const filters = this.setFilters({ Fields: params.field, isVisible: true, from: params.from, size: params.to });
    const sub = this.apiProfessionsService
      .getElasticProfessionsByFilters(filters)
      .pipe(take(1))
      .subscribe((jobs: IProfession[]) => {
        if (this.profession && this.profession.id) {
          this.similarJobs = jobs.filter((j: IProfession) => j.id !== this.profession.id);
        } else {
          this.similarJobs = jobs;
        }
        this.overlayService.hide();
        this.dataFetched = true;
      });
  }

  setFilters(filtersObject) {
    let regionIdValue: string;
    let filtersResultObject: any = filtersObject;
    switch (this.userRole) {
      case 'tutor':
        regionIdValue = EmptyGuid;
        filtersResultObject = Object.assign(filtersResultObject, { RegionId: regionIdValue });
        break;
      default:
        const isPilot: boolean = this.webStorageService.get(StorageKeys.IsPilot) == 'true';
        const regionId: string = this.webStorageService.get(StorageKeys.RegionId);
        regionIdValue = regionId && regionId != 'null' && !isPilot ? regionId : EmptyGuid;
        filtersResultObject = Object.assign(filtersResultObject, { RegionId: regionIdValue });

        if (!isPilot) {
          const municipalityId = this.webStorageService.get(StorageKeys.MunicipalityId);
          const municipalityIdValue = municipalityId && municipalityId != 'null' ? municipalityId : null;
          filtersResultObject = Object.assign(filtersResultObject, {
            MunicipalityId: municipalityIdValue,
          });
        }
    }
    return filtersResultObject;
  }

  showProfession(job) {
    this.router.navigate(['/professions', job.hrid]);
  }
}
