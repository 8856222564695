import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ILessonResultsCount,
  IMappedLessonData,
  IPassedLessonData,
  IPupil,
  ITask,
} from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import { TaskTypes } from 'app/shared/dashboard/career-education/collections/collections';
import { ICourseMaterialExt } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { TeacherClassCoursesService } from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import { ILesson, ILessonActivity ,ApiCoursesMaterialsService, ApiTemporaryService, B2gReportsService, Helper, IChoosesProfessionsReport } from '@profilum-library';
import { DictionaryType } from '../../../../landing/base/landing-base.dictionary';
import { RootScopeService } from '../../../services/root-scope.service';

@Injectable()
export class TeachersJournalService {
  private readonly lessonResults$: BehaviorSubject<ILessonResultsCount>;
  private lessonResults: ILessonResultsCount;
  private lessonActivitiesObject: Record<string, Record<string, ILessonActivity[]>>;
  private dictionary: DictionaryType;

  constructor(
    private apiTemporaryService: ApiTemporaryService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private rootScopeService: RootScopeService,
  ) {
    this.dictionary = this.rootScopeService.getDictionary();
    this.lessonResults$ = new BehaviorSubject<ILessonResultsCount>(null);
  }

  public get lessonResultsCount$(): Observable<ILessonResultsCount> {
    return this.lessonResults$.asObservable();
  }

  public getLessons(schoolClassId: string, courseIds: string[]): Observable<IMappedLessonData[]> {
    this.lessonResults = {};

    return forkJoin([
      this.apiCoursesMaterialsService.getLessonsByCourseIds(courseIds),
      this.apiCoursesMaterialsService.getPassedLessonActivity(courseIds, schoolClassId),
      this.apiCoursesMaterialsService.getOneCourseMaterial(courseIds[0]),
      this.apiTemporaryService.getPupils(schoolClassId),
    ]).pipe(
      map(([lessons, lessonActivities, courseMaterial, pupils]: [ILesson[], ILessonActivity[], ICourseMaterialExt, IPupil[]]) => {
        this.createLessonActivityObject(lessonActivities);
        this.lessonResults$.next(this.lessonResults);
        return this.sortLessons(this.parseLessons(lessons, courseMaterial, pupils));
      }),
    );
  }

  private createLessonActivityObject(lessonActivities: ILessonActivity[]): void {
    this.lessonActivitiesObject = lessonActivities.reduce((previousValue, currentValue) => {
      if (!previousValue[currentValue.userId]) {
        previousValue[currentValue.userId] = {};
      }

      if (previousValue[currentValue.userId][currentValue.lessonId]?.length) {
        previousValue[currentValue.userId][currentValue.lessonId].push(currentValue);
      } else {
        previousValue[currentValue.userId][currentValue.lessonId] = [currentValue];
      }
      return previousValue;
    }, {});
  }

  private parseLessons(lessons: ILesson[], courseMaterial: ICourseMaterialExt, pupils: IPupil[]): IMappedLessonData[] {
    const sortedLessonMaterials = courseMaterial.lessonsMaterials.sort((a, b) => a.number - b.number);

    return pupils.map((pupil: IPupil) => {
      let hasPassedLesson: boolean = false;

      const passedLessonData: IPassedLessonData[] = sortedLessonMaterials.map(material => {
        const tasksExist = material.hasLinkedTaskForPupil;
        const lesson = lessons
          .sort((lesson1, lesson2) => lesson1.orderNumber - lesson2.orderNumber)
          .find((lesson: ILesson) => lesson.lessonMaterialId === material.id);
        const task: ITask[] = [];
        let isPassed: boolean = false;
        let twoTasks: boolean = false;
        const tooltipText: string[] = [];
        const passedTasks: boolean[] = [false, false];
        const passedTasksObj: Record<string, boolean> = {};
        if (tasksExist && lesson) {
          // todo можно ли определить что из этого выбор профессии?
          const lessonActivity = this.lessonActivitiesObject[pupil.userId]
            ? this.lessonActivitiesObject[pupil.userId][lesson.lessonMaterialId] ?? null
            : null;
          if (lessonActivity) {
            for (const activity of lessonActivity) {
              if (!activity.taskId) {
                passedTasks[0] = activity.isPassed;
              } else {
                if (lesson.tasks?.length) {
                  for (let i = 0; i < lesson.tasks.length; i++) {
                    if (lesson.tasks[i]?.id === activity.taskId) {
                      const taskId: string = lesson.tasks[i].id;
                      passedTasksObj[taskId] = activity.isPassed;
                      passedTasks[i] = activity.isPassed;
                    }
                  }
                }
              }
            }
          }
          task[0] = lesson.tasks?.length ? lesson.tasks[0] : null;
          task[1] = lesson.tasks?.length == 2 ? lesson.tasks[1] : null;
          twoTasks = lesson.tasks?.length == 2;
          isPassed = passedTasks[0] && passedTasks[1];

          lesson.tasks?.forEach(task => tooltipText.push(task.text));
        }

        this.setLessonResult(material.id, tasksExist, passedTasks, passedTasksObj);
        hasPassedLesson = hasPassedLesson ? hasPassedLesson : passedTasks[0];
        return {
          isPassed,
          isDiagnostic: tasksExist && (task[0]?.type === TaskTypes.ScreeningTest || task[1]?.type === TaskTypes.ScreeningTest),
          lesson,
          tasksExist,
          tooltipText: tooltipText.length > 0 ? tooltipText : [`В этом занятии ${this.dictionary.Pupils} не работают с платформой`],
          materialId: material.id,
          orderNumber: material.number,
          twoTasks,
          passedTasks,
          passedTasksObj,
        };
      });
      const fullName = `${pupil.lastName as string} ${pupil.firstName as string}`;
      return {
        lessonData: passedLessonData,
        pupil,
        allNotPassed: !hasPassedLesson,
        fullName: fullName.length > 30 ? fullName.substring(0, 30) + '...' : fullName,
      };
    });
  }

  private setLessonResult(lessonId: string, tasksExist: boolean, isPassed, isPassedObj): void {
    if (!tasksExist) {
      this.lessonResults[lessonId] = null;
      return;
    }

    if (!this.lessonResults[lessonId]) {
      this.lessonResults[lessonId] = [0, 0];
    }

    if (isPassed[0]) {
      this.lessonResults[lessonId][0] = this.lessonResults[lessonId][0] + 1;
    }
    if (isPassed[1]) {
      this.lessonResults[lessonId][1] = this.lessonResults[lessonId][1] + 1;
    }

    if (Object.keys(isPassedObj).length) {
      for (const passedElement in isPassedObj) {
        if (isPassedObj[passedElement]) {
          this.lessonResults[lessonId][passedElement] =
            this.lessonResults[lessonId][passedElement] >= 0 ? this.lessonResults[lessonId][passedElement] + 1 : 1;
        }
      }
    }
  }

  private sortLessons(mappedLessons: IMappedLessonData[]): IMappedLessonData[] {
    return mappedLessons.sort((value1, value2) => {
      const sortingByLastName: boolean = value1.pupil.lastName !== value2.pupil.lastName;
      return sortingByLastName
        ? Helper.sorting(value1.pupil.lastName, value2.pupil.lastName)
        : Helper.sorting(value1.pupil.firstName, value2.pupil.firstName);
    });
  }
}
