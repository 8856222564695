import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { UnsubscribeComponent } from "@profilum-components/unsubscribe/unsubscribe.component";
import { SectionTypes } from '../collections/prf-tests.collections';
import { IAnswerInfo, ISectionInfo } from '../interfaces/prf-tests.interface';

@Component({
  selector: 'prf-rectangle-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rectangle-view.component.html',
  styleUrls: ['./rectangle-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RectangleViewComponent extends UnsubscribeComponent implements OnInit {
  @Input() public answerInfo: IAnswerInfo;
  @Input() public sectionInfo: ISectionInfo;
  @Output() public selectedAnswer: EventEmitter<IAnswerInfo> = new EventEmitter<IAnswerInfo>();
  @Input() public updateSelections?: Observable<string>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    if (!this.answerInfo && !this.sectionInfo) {
      return;
    }

    this.updateSelections?.pipe(this.unsubscribeOperator).subscribe((answerId: string) => {
      if (answerId === this.answerInfo.answerId) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public selectAnswer(): void {
    if (this.isSelectedBefore()) {
      return;
    }

    if (!this.answerInfo.sectionId) {
      this.answerInfo.sectionId = this.sectionInfo.sectionId;
      this.answerInfo.sectionType = this.sectionInfo.sectionType;
    } else {
      this.answerInfo.sectionId = null;
      this.answerInfo.sectionType = null;
    }

    this.selectedAnswer.emit(this.answerInfo);
  }

  public isSelectedBefore(): boolean {
    return this.answerInfo.sectionId && this.answerInfo.sectionId !== this.sectionInfo.sectionId;
  }

  public isSelectedCurrently(): boolean {
    return this.answerInfo.sectionId && this.answerInfo.sectionId === this.sectionInfo.sectionId;
  }

  public isPositive(): boolean {
    if (this.isSelectedBefore()) {
      return this.answerInfo.sectionType === SectionTypes.Positive;
    } else {
      return this.sectionInfo.sectionType === SectionTypes.Positive;
    }
  }
}
