import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NotificationCourseMaterialsComponent } from './notification-course-materials/notification-course-materials.component';
import { CourseMaterialSectionComponent } from './course-material-section/course-material-section.component';
import { CourseMaterialBlockComponent } from './course-material-section/course-material-block/course-material-block.component';
import { CourseMaterialsComponent } from './course-materials.component';

@NgModule({
  declarations: [CourseMaterialsComponent, CourseMaterialSectionComponent, NotificationCourseMaterialsComponent],
  imports: [CommonModule, NgOptimizedImage, CourseMaterialBlockComponent],
  exports: [CourseMaterialsComponent, CourseMaterialSectionComponent],
})
export class CourseMaterialsModule {}
