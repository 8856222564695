import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegistrationChangePasswordComponent } from 'app/landing/base/registration/registration-change-password/registration-change-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'registration',
    loadComponent: () => import('app/landing/base/registration/registration.component').then(mod => mod.RegistrationComponent),
    pathMatch: 'full',
  },
  {
    path: 'registration-parent',
    loadChildren: () =>
      import('app/landing/base/registration/open-registration-redesign/open-registration-redesign.module').then(
        m => m.OpenRegistrationRedesignModule,
      ),
    pathMatch: 'full',
  },
  {
    path: 'code-registration',
    loadComponent: () =>
      import('app/landing/base/registration/pupil-code-registration/pupil-code-registration.component').then(
        m => m.PupilCodeRegistrationComponent,
      ),
    pathMatch: 'full',
  },
  {
    path: 'registration-teacher',
    loadComponent: () =>
      import('app/landing/base/registration/code-registration/code-registration.component').then(m => m.CodeRegistrationComponent),
    pathMatch: 'full',
  },
  {
    path: 'registration-other',
    loadComponent: () =>
      import('app/landing/base/registration/code-registration/code-registration.component').then(m => m.CodeRegistrationComponent),
    pathMatch: 'full',
  },
  {
    path: 'tokenauth',
    loadComponent: () => import('app/landing/base/tokenauth/tokenauth.component').then(m => m.TokenauthComponent),
  },

  {
    path: 'openregistration',
    loadComponent: () =>
      import('app/landing/base/registration/open-registration/open-registration.component').then(m => m.OpenRegistrationComponent),
  },
  { path: 'vorobievi-gori/login', component: LoginComponent },
  { path: 'vorobievi-gori', redirectTo: 'vorobievi-gori/registration', pathMatch: 'full' },
  {
    path: 'vorobievi-gori/registration',
    loadComponent: () =>
      import('app/landing/base/registration/open-registration/open-registration.component').then(m => m.OpenRegistrationComponent),
  },
  {
    path: 'change-password',
    component: RegistrationChangePasswordComponent,
    data: { roles: ['pupil', 'teacher', 'schooladmin', 'parent'] },
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('app/landing/base/registration/pupil-code-registration/pupil-code-registration.component').then(
        m => m.PupilCodeRegistrationComponent,
      ),
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingBaseRoutingModule {}
