import { ChangeDetectorRef, Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { CodeInputStateEnum } from '@profilum-components/code-input/code-input.config';
import { CodeInputComponent } from '@profilum-components/code-input/code-input.component';
import { ButtonType } from '@profilum-collections/common.collections';
import { B2gEurochemService, ICheckReferralResponse, StorageKeys, WebStorageService } from '@profilum-library';

import { FormGeneratorComponent } from 'app/shared/common-components/form-generator/form-generator.component';
import { EurochemService } from 'app/shared/services/eurochem.service';
import { EUROCHEM_DICTIONARY } from '../dictionary';

@Component({
  selector: 'prf-registration-parent',
  templateUrl: './registration-parent.component.html',
  styleUrl: './registration-parent.component.scss',
  providers: [B2gEurochemService],
})
export class RegistrationParentComponent extends UnsubscribeComponent implements OnInit {
  public guId: string;
  public formConfig = 'registration-parent.form.json';
  public confirmButtonText: string = 'Зарегистрироваться';

  public isReferralValid: boolean;

  @ViewChild('parentRegistrationForm') public parentRegistrationForm: FormGeneratorComponent;
  @ViewChild('codeInput') public codeInput!: CodeInputComponent;

  private changeDetectorRef = inject(ChangeDetectorRef);
  private eurochemService = inject(EurochemService);
  private webStorageService = inject(WebStorageService);

  constructor(private route: ActivatedRoute) {
    super();
  }

  public ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.guId = params.get('hrid');
      this.eurochemService
        .checkReferral(this.guId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((response: ICheckReferralResponse) => {
          this.isReferralValid = !(response.status !== 'Success' || !response.isValid);
          this.changeDetectorRef.detectChanges();
        });
    });
  }

  public getInviteLink(): void {
    window.open(`${window.location.origin}/register-consent/${this.guId}?isParent=true`, '_self');
  }

  protected readonly CodeInputStateEnum = CodeInputStateEnum;
  protected readonly ButtonType = ButtonType;
  protected readonly EUROCHEM_DICTIONARY = EUROCHEM_DICTIONARY;
}
