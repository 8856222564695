import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedBaseModule } from 'app/shared/shared-base.module';
import { SharedModule } from 'app/shared/shared.module';
import { ActiveInternshipsComponent } from './all-internships/active-internships/active-internships.component';
import { AddInternshipComponent } from './all-internships/active-internships/add-internship/add-internship.component';
import { SelectTalentsComponent } from './all-internships/active-internships/add-internship/select-talents/select-talents.component';
import { SelectThemesComponent } from './all-internships/active-internships/add-internship/select-themes/select-themes.component';
import { EditInternshipComponent } from './all-internships/active-internships/edit-internship/edit-internship.component';
import { FiltersInternshipsComponent } from './all-internships/active-internships/filters-internships/filters-internships.component';
import { InternshipsFilterGroupComponent } from './all-internships/active-internships/filters-internships/internships-filter-group/internships-filter-group.component';
import { InternshipsFilterTerritoryComponent } from './all-internships/active-internships/filters-internships/internships-filter-territory/internships-filter-territory.component';
import { InternshipCardComponent } from './all-internships/active-internships/internship/internship-card/internship-card.component';
import { InternshipDetailsComponent } from './all-internships/active-internships/internship/internship-details/internship-details.component';
import { InternshipDetailsService } from './all-internships/active-internships/internship/internship-details/internship-details.service';
import { InternshipsListComponent } from './all-internships/active-internships/internship/internships-list.component';
import { SearchInternshipsComponent } from './all-internships/active-internships/search-internships/search-internships.component';
import { AllInternshipsComponent } from './all-internships/all-internships.component';
import { ArchiveInternshipsComponent } from './all-internships/archive-internships/archive-internships.component';
import { InternshipsListArchiveComponent } from './all-internships/archive-internships/internships-list-archive/internships-list-archive.component';
import { EmployerInternshipsComponent } from './employer-internships/employer-internships.component';
import { InternshipsWelcomePageComponent } from './internships-welcome-page/internships-welcome-page.component';
import { InternshipsComponent } from './internships.component';
import { MineInternshipsComponent } from './mine-internships/mine-internships.component';

@NgModule({
  imports: [RouterModule, SharedBaseModule],
  declarations: [
    InternshipsComponent,
    SearchInternshipsComponent,
    AllInternshipsComponent,
    MineInternshipsComponent,
    AddInternshipComponent,
    EditInternshipComponent,
    ActiveInternshipsComponent,
    ArchiveInternshipsComponent,
    FiltersInternshipsComponent,
    InternshipsListComponent,
    InternshipsListArchiveComponent,
    InternshipCardComponent,
    SelectThemesComponent,
    SelectTalentsComponent,
    InternshipsFilterGroupComponent,
    InternshipsFilterTerritoryComponent,
    InternshipsWelcomePageComponent,
    EmployerInternshipsComponent,
    InternshipDetailsComponent,
  ],
  providers: [InternshipDetailsService],
  bootstrap: [],
  exports: [InternshipsWelcomePageComponent, EmployerInternshipsComponent, InternshipDetailsComponent],
})
export class InternshipsModule {}
