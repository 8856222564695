import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { FilterSchoolsPipe } from 'app/shared/pipes/filter-schools.pipe';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { RegistrationBase } from '../../../../shared/auth-classes/registration.base';
import { of } from 'rxjs';
import {
  ApiAuthService,
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  Helper,
  IProsvetUserData,
  StorageKeys, UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

@Component({
  selector: 'prf-registration-pupil-prosvet',
  templateUrl: './registration-pupil-prosvet.component.html',
  styleUrls: ['./registration-pupil-prosvet.component.scss'],
})
export class RegistrationPupilProsvetComponent extends RegistrationBase<B2gSaasService> {
  @Input() prosvetUserData: IProsvetUserData;
  public personalTerms: boolean = true;
  public prosvetUserRegistration: boolean = false;

  constructor(
    registrationService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    apiSchoolsService: ApiSchoolsService,
    filterSchoolsPipe: FilterSchoolsPipe,
    filterByNamePipe: FilterByNamePipe,
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    b2gSaasService: B2gSaasService,
    apiLocationsService: ApiLocationsService,
    userDataHandlerService: UserDataHandlerService,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected yandexMetricsService: YandexMetricsService,
    private apiAuthService: ApiAuthService,
  ) {
    super(
      userDataHandlerService,
      yandexMetricsService,
      registrationService,
      router,
      fb,
      apiLocationsService,
      apiSchoolsService,
      filterSchoolsPipe,
      filterByNamePipe,
      translateService,
      appSettingsService,
      b2gSaasService,
      webStorageService,
      userStorageService,
    );
  }

  async ngOnInit() {
    this.defaultRegion = AppSettingsService.settings.regionId;
    await super.ngOnInit();

    if (this.prosvetUserData != null) {
      // SchoolPortal registration
      this.prosvetUserRegistration = true;
      this.setUserDataValues();
    } else {
      // open registration
      this.prosvetUserRegistration = false;
    }
  }

  protected initFormGroup() {
    this.form = this.fb.group({
      region: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      date: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.dateRegExp)]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(this.passwordRegExp),
      ]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      school: new UntypedFormControl(null, [Validators.required]),
      schoolClass: new UntypedFormControl(null),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl('M', [Validators.required]),
      personalTerms: new UntypedFormControl(false, [Validators.requiredTrue]),
    });
  }

  public registerBase() {
    this.submitted = true;
    if (this.form.valid) {
      // todo need a phoneNumber & isConsentToMailing fields to registration
      const credentials = {
        city: this.f.city.value.data.name,
        regionId: this.f.region.value.data.id,
        municipalityId: this.f.city.value.data.municipalityId,
        birthday: DateHelper.toDayJs(this.f.date.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        password: this.f.password.value,
        role: this.f.role.value,
        gender: this.f.gender.value,
        schoolId: this.f.school.value.data.id,
        schoolClassLetter: this.f.schoolClassLetter.value && this.f.schoolClassLetter.value.name,
        schoolClassNumber: this.f.schoolClassNumber.value && this.f.schoolClassNumber.value.name,
        tag: this.tag,
      };
      if (credentials) {
        this.registrationFailed = false;
        this.passFailed = false;
        this.apiAuthService
          .registrationProsvet(credentials)
          .pipe(
            switchMap((response: any) => {
              if (response) {
                if (response.userId == undefined || response.userId == null) {
                  this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                  this.registrationFailed = true; // ошибка регистрации
                  this.failWaiting();
                  return of(null);
                } else {
                  if (this.tag) {
                    this.yandexMetricsService.reachGoal(YmItems.PARTNER_EMAIL_REGISTRATION);
                  }

                  this.yandexMetricsService.reachGoal(YmItems.PUPIL_REG);

                  // Сразу попадаем в ЛК
                  return this.apiAuthService.loginProsvet(response.userId, response.email).pipe(
                    takeUntil(this.unsubscribe),
                    switchMap((loginResult: any) => {
                      if (loginResult) {
                        if (loginResult.succeeded === false) {
                          this.loginFailed = true;
                          this.passFailed = true;
                          return of(null);
                        } else {
                          this.userStorageService.setUserId = loginResult.userId;
                          this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                          this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                          this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                          this.webStorageService.set(StorageKeys.IsAuthorized, true);
                          return this.registrationService.getAccess(loginResult.userId).pipe(
                            tap((accessResponse: any) => {
                              this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                              this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);
                              switch (loginResult.role) {
                                case 'pupil': {
                                  return this.router.navigate(['/pupil']);
                                }
                              }
                            }),
                          );
                        }
                      } else return of(null);
                    }),
                  );
                }
              } else return of(null);
            }),
            takeUntil(this.unsubscribe),
          )
          .subscribe(_ => this.removeWaiting());
      }
    } else {
      this.failWaiting();
    }
  }

  public registerProsvetUser() {
    this.submitted = true;
    if (this.form.valid) {
      const school = this.schools.find(school => school.id === this.prosvetUserData.schoolId);
      const city = this.cities.find(city => city.name === this.prosvetUserData.city);
      let classLetter: string;
      let classNumber: number;
      const schoolClassInfo = this.f.schoolClass.value ? Helper.parseSchoolClassName(this.f.schoolClass.value.name) : null;

      if (schoolClassInfo) {
        classLetter = schoolClassInfo.classLetter;
        classNumber = schoolClassInfo.classNumber;
      }

      const prosvetCredentials = {
        externalAppUserId: this.prosvetUserData.externalAppUserId,
        email: this.f.email.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        lastName: this.f.lastName.value,
        phoneNumber: '',
        role: this.f.role.value,
        gender: this.f.gender.value,
        birthday: DateHelper.toDayJs(this.f.date.value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        regionId: this.f.region.value.data.id,
        municipalityId: this.prosvetUserData.municipalityId,
        city: city && city.name ? city.name : this.f.city.value.name,
        schoolId: school && school.id ? school.id : this.f.school.value.data.id,
        schoolClassId: this.f.schoolClass.value ? this.f.schoolClass.value.data.id : null,
        schoolClassLetter: this.f.schoolClassLetter.value ? this.f.schoolClassLetter.value.name : classLetter ? classLetter : null,
        schoolClassNumber: this.f.schoolClassNumber.value ? this.f.schoolClassNumber.value.name : classNumber ? classNumber : null,
        isConsentToMailing: false,
      };
      if (prosvetCredentials) {
        this.registrationFailed = false;
        this.passFailed = false;
        this.apiAuthService
          .registrationProsvet(prosvetCredentials)
          .pipe(
            switchMap((response: any) => {
              if (response) {
                if (response.userId == undefined || response.userId == null) {
                  this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                  this.registrationFailed = true; // ошибка регистрации
                  this.failWaiting();
                  return of(null);
                } else {
                  if (this.tag) {
                    this.yandexMetricsService.reachGoal(YmItems.PARTNER_EMAIL_REGISTRATION);
                  }

                  this.yandexMetricsService.reachGoal(YmItems.PUPIL_REG);

                  // Сразу попадаем в ЛК
                  return this.apiAuthService.loginProsvet(response.userId, prosvetCredentials.email).pipe(
                    switchMap((loginResult: any) => {
                      if (loginResult) {
                        if (loginResult.succeeded === false) {
                          this.loginFailed = true;
                          this.passFailed = true;
                          return of(null);
                        } else {
                          this.userStorageService.setUserId = loginResult.userId;
                          this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                          this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                          this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                          this.webStorageService.set(StorageKeys.IsAuthorized, true);
                          return this.registrationService.getAccess(loginResult.userId).pipe(
                            tap((accessResponse: any) => {
                              this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                              this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);
                              switch (loginResult.role) {
                                case 'pupil': {
                                  return this.router.navigate(['/pupil']);
                                }
                              }
                            }),
                          );
                        }
                      } else return of(null);
                    }),
                  );
                }
              } else return of(null);
            }),
            takeUntil(this.unsubscribe),
          )
          .subscribe(_ => this.removeWaiting());
      }
    } else {
      this.failWaiting();
    }
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    if (this.prosvetUserRegistration) {
      this.registerProsvetUser();
    } else {
      this.registerBase();
    }
  }

  // данные пользователя после авторизации на "Просвещении"
  private setUserDataValues(): void {
    const isValidDate = this.prosvetUserData.birthday && this.prosvetUserData.birthday !== '0001-01-01T00:00:00'; // в таком формте приходит отсутствие даты
    const birthday = isValidDate ? DateHelper.toDayJs(this.prosvetUserData.birthday).format('DD/MM/YYYY') : null;

    const region = this.regions.find(region => region.id === this.prosvetUserData.regionId); //'19594e36-b694-44e3-b0ed-8eea3be8bf6b');
    const school = this.schools.find(school => school.id === this.prosvetUserData.schoolId);
    const city = this.cities.find(city => city.name === this.prosvetUserData.city);

    if (school && school.id) {
      // запрос классов по школе
      this.getSchoolClassBySchool(school.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassLetter.reset();
        });
    }

    this.form = this.fb.group({
      //region: new FormControl(this.regionsView.find(r => r.data.id === this.defaultRegion)),
      region: new UntypedFormControl(
        {
          value: region ? { name: region.viewValue, data: region } : null,
          disabled: !!region,
        },
        [Validators.required],
      ),
      lastName: new UntypedFormControl(
        {
          value: this.prosvetUserData.lastName,
          disabled: !!this.prosvetUserData.lastName,
        },
        [Validators.required],
      ),
      firstName: new UntypedFormControl(
        {
          value: this.prosvetUserData.firstName,
          disabled: !!this.prosvetUserData.firstName,
        },
        [Validators.required],
      ),
      middleName: new UntypedFormControl(
        {
          value: this.prosvetUserData.middleName,
          disabled: !!this.prosvetUserData.middleName,
        },
        [Validators.required],
      ),
      date: new UntypedFormControl({ value: isValidDate ? birthday : null, disabled: isValidDate }, [
        Validators.required,
        Validators.pattern(this.dateRegExp),
      ]),
      email: new UntypedFormControl({ value: this.prosvetUserData.email, disabled: !!this.prosvetUserData.email }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      city: new UntypedFormControl(
        {
          value: city ? { name: city.name, data: city } : null,
          disabled: !!city,
        },
        [Validators.required],
      ),
      school: new UntypedFormControl(
        {
          value: school ? { name: school.viewValue, data: school } : null,
          disabled: !!school,
        },
        [Validators.required],
      ),
      schoolClass: new UntypedFormControl(null),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl(
        {
          value: this.prosvetUserData.gender && ['M', 'F'].indexOf(this.prosvetUserData.gender) > -1 ? this.prosvetUserData.gender : null,
          disabled: this.prosvetUserData.gender && ['M', 'F'].indexOf(this.prosvetUserData.gender) > -1,
        },
        [Validators.required],
      ),
      personalTerms: new UntypedFormControl((this.personalTerms = false), [Validators.requiredTrue]),
      registeredByInstitutionId: new UntypedFormControl(null, []),
    });

    this.f.region.reset();

    //нужно, чтобы подтянулись города по муниципалитету
    this.f.region.value && !this.f.city.value ? this.onRegionChange() : null;

    // нужно, чтобы подтянулись школы по городу
    this.f.city.value && !this.f.school.value ? this.onCityChange() : null;

    // вызываем проверку возраста
    this.checkFormatDate(this.f.date.value);
  }

  onRegionChange() {
    this.f.city.reset();
    this.f.city.disabled ? this.f.city.enable() : null;
    this.onCityChange();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }
  onCityChange() {
    this.f.school.reset();
    this.f.school.disabled ? this.f.school.enable() : null;
    this.onSchoolChange();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }
  onSchoolChange() {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  get isAccessAllowed() {
    if (this.prosvetUserRegistration) {
      return this.form.valid && this.isClassSelected;
    } else {
      return this.form.valid && this.isClassSelected && this.isValidPassword(this.f.password.value);
    }
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathRegion;
  }
}
