<div class="prf-login-page">
  <div class="prf-login">
    <a routerLink="/" class="prf-login-logo">
      <img src="./profilum-assets/images/eurochem/logo_big.png" alt="EUROCHEM" />
    </a>
    <ng-container *ngTemplateOutlet="isReferralValid ? DEFAULT_TEMPLATE : NOT_VALID_REFERRAL_TEMPLATE" />
  </div>
  <prf-page-footer class="prf-footer"></prf-page-footer>
</div>

<ng-template #DEFAULT_TEMPLATE>
  <prf-form
    #parentRegistrationForm
    [configName]="formConfig"
    [acceptButton]="confirmButtonText"
    [isButtonsVisible]="false"
  />
    <prf-button
      [isFullWidth]="true"
      [buttonTitle]="confirmButtonText"
      [type]="ButtonType.Eurochem"
      [disabled]="!parentRegistrationForm.isFormValid"
      (emitClick)="getInviteLink()"
    />
</ng-template>

<ng-template #NOT_VALID_REFERRAL_TEMPLATE>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL1 }}</p>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL2 }}</p>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL3 }}</p>
</ng-template>
