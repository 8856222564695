import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiCoursesMaterialsService,
  ApiUsersService,
  AppSettingsService,
  AuthHandlerService,
  B2gSaasService,
  EmptyGuid,
  Helper,
  LocationEnum,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';

@Directive()
export abstract class LoginBase<LoginService> extends UnsubscribeComponent implements OnInit {
  public form: UntypedFormGroup;
  public passFailed: boolean = false;
  public buttonStates: any = {
    waiting: false,
    active: false,
    activated: false,
  };

  public mask: any;
  public isKruzhkiPage: boolean = false;

  private defaultTerritoryId: string = EmptyGuid;

  protected constructor(
    protected meta: Meta,
    protected translateService: TranslateService,
    protected overlayService: OverlayBusyService,
    protected loginService: LoginService | any,
    protected b2gSaasService: B2gSaasService,
    protected router: Router,
    protected fb: UntypedFormBuilder,
    @Inject(DOCUMENT) public document: Document,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected appSettings: AppSettingsService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    protected apiUsersService: ApiUsersService,
    private authHandlerService: AuthHandlerService,
  ) {
    super();
    this.getTranslation('SHARED.ENTRANCE')
      .pipe(take(1))
      .subscribe((translation: any) =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
    this.isKruzhkiPage = this.appSettings.currentLocation === LocationEnum.KRUZHKI;
  }

  public ngOnInit(): void {
    this.createForm();
    this.overlayService.hide();
  }

  protected async doLogin(): Promise<any> {
    this.clearPasswordSpaces();
    let login: string = this.loginForm.login.value;
    if (REG_EXP.phoneRegExp.test(login)) {
      login = login?.replace(/\D/g, '');
      if (login) {
        login = Helper.phoneValidation(login);
      }
    } else if (this.isKruzhkiPage && Helper.isValidEmail(login)) {
      login = login.trim();
    }
    this.authHandlerService.login(login, this.loginForm.password.value);

    this.timer = setTimeout(() => {
      this.passFailed = this.webStorageService.get(StorageKeys.LoginFailed);
      this.webStorageService.clear(StorageKeys.LoginFailed);
    }, 500);
  }

  public clearPasswordSpaces(): void {
    const insertedCode: string = this.loginForm.password.value;
    const codeWithoutSpaces: string = insertedCode.replace(/[ ]/g, '');
    this.loginForm.password.setValue(codeWithoutSpaces);
    this.loginForm.password.updateValueAndValidity();
  }

  public clearLoginSpaces(): void {
    const loginWithoutSpaces: string = this.loginForm.login.value?.trim();
    this.loginForm.login.setValue(loginWithoutSpaces);
    this.loginForm.login.updateValueAndValidity();
  }

  public onClickLogin(): void {
    if (this.isAccessDenied) {
      throw new Error('Access denied!');
    }
    this.doLogin();
  }

  protected createForm() {
    this.form = this.fb.group({
      login: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required]),
    });

    this.form.get('login')['focused'] = false;
    this.form.get('password')['focused'] = false;
  }

  get loginForm(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  get isAccessDenied() {
    return this.loginForm.login.invalid || this.loginForm.password.invalid;
  }

  get isLoginInvalid() {
    return this.loginForm.login.dirty && this.loginForm.login.invalid && this.loginForm.login.errors;
  }

  get isPasswordInvalid() {
    return this.loginForm.password.dirty && this.loginForm.password.invalid && this.loginForm.password.errors;
  }

  // Методы переключение состояний кнопки (анимации)
  protected removeWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.activated = true;
  }

  protected failWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.active = false;
    this.buttonStates.activated = false;
  }

  public animateLogin() {
    if (this.buttonStates.waiting) {
      return;
    }

    this.buttonStates.active = true;
    this.buttonStates.waiting = true;
    this.doLogin();
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public checkLogin(): void {
    this.loginForm.login['focused'] = false;
    this.clearLoginSpaces();
    if (!this.loginForm.login.value) {
      return;
    }

    if (!this.isKruzhkiPage && REG_EXP.phoneRegExp.test(this.loginForm.login.value)) {
      let phone = this.loginForm.login.value?.replace(/\D/g, '');
      if (phone) {
        phone = Helper.phoneValidation(phone);
      }
      if (!Helper.isValidPhoneNumber(phone)) {
        this.loginForm.login.setErrors({ pattern: true });
        return;
      }

      this.authHandlerService.checkPhoneAvailability(phone).subscribe(response => {
        if (response.free) {
          this.loginForm.login.setErrors({ isNotUsed: true });
        }
        return;
      });
      return;
    } else if (REG_EXP.emailRegExp.test(this.loginForm.login.value)) {
      const email = this.loginForm.login.value;

      if (!Helper.isValidEmail(email)) {
        this.loginForm.login.setErrors({ pattern: true });
        return;
      }

      this.authHandlerService.checkEmailAvailability(email).subscribe(response => {
        if (response.isFree) {
          this.loginForm.login.setErrors({ isNotUsed: false });
          this.loginForm.login.setErrors({ emailIsNotUsed: true });
        }
        return;
      });

      return;
    }
    this.loginForm.login.setErrors({ pattern: true });
  }
}
