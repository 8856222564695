import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiFieldsService, ApiInternshipsService, ApiSearchService, IFilterInternships } from '@profilum-library';
import { InternshipsFiltersService } from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { forkJoin as observableForkJoin } from 'rxjs';

@Component({
  selector: 'prf-internships-filter-group',
  templateUrl: './internships-filter-group.component.html',
  styleUrls: ['./internships-filter-group.component.scss'],
})
export class InternshipsFilterGroupComponent implements OnInit, OnDestroy {
  groups: any;
  filters: IFilterInternships;
  selectedCourses: any = [];
  subscription: any;
  searchCourse: string = '';
  selectedGroup: any = [];
  groupOpen: boolean = false;
  @ViewChild('groupFilter') groupFilter: ElementRef;
  @ViewChild('groupDrop') groupDrop: ElementRef;

  constructor(
    private apiInternshipsService: ApiInternshipsService,
    private filtersService: InternshipsFiltersService,
    private apiFieldsService: ApiFieldsService,
    private apiSearchService: ApiSearchService,
  ) {}

  ngOnInit() {
    this.subscription = this.filtersService.getInternshipsFilters().subscribe(data => {
      this.filters = data;
      this.selectedCourses = data.Courses ? data.Courses : [];
    });
    observableForkJoin([
      this.apiSearchService.getTalentGroupCourse(),
      this.apiFieldsService.getGroups(),
      this.apiInternshipsService.getInternshipsCountStatistic(),
    ]).subscribe(([talentGroupCourse, courseGroups, classesCountStatistic]) => {
      talentGroupCourse.map(({ talent: name, groups }) => ({ name, groups }));

      this.groups = courseGroups.fieldDOs.map(({ name }) => ({ name, courses: [] }));
      talentGroupCourse.forEach(({ groups = [] }) => {
        groups.forEach(({ group, courses = [] }) => {
          let courseGroup = this.groups.find(({ name }) => name === group);

          if (!courseGroup) {
            courseGroup = {
              name: group,
              courses: [],
            };
            this.groups.push(courseGroup);
          }

          courses.forEach(course => {
            if (!courseGroup.courses.find(item => item.name === course)) {
              let count = classesCountStatistic.coursesCount[course];
              courseGroup.courses.push({ name: course, count: count == null ? 0 : count });
            }
          });
        });
      });
      this.groups = this.groups.filter(({ courses }) => courses.length);
      this.groups.forEach(group => {
        group.courses.sort();
        group.courses = group.courses.map(item => ({ item }));
      });
    });
  }

  checkFilterHeader() {
    if (this.filters.Courses && this.filters.Courses.length > 0) {
      if (this.filters.Courses.length > 1) {
        let courseTitles = ['направление', 'направления', 'направлений'];
        let n = this.filters.Courses.length;
        let title = this.filtersService.getTitleByNumber(n, courseTitles);
        return n + ' ' + title;
      } else {
        let course = this.selectedCourses.filter(el => el === this.filters.Courses[0]);
        return course[0];
      }
    } else {
      return 'Направление';
    }
  }

  setCourseFilter(course: any) {
    if (!this.filters.Courses) {
      this.filters.Courses = [course.item.name];
    } else {
      let index = this.selectedCourses.indexOf(course.item.name);
      index !== -1 ? this.filters.Courses.splice(index, 1) : this.filters.Courses.push(course.item.name);
    }
    this.filtersService.setInternshipsFilter(this.filters);
    this.checkGroupName();
  }

  deselectCourses() {
    this.filters.Courses = null;
    this.selectedGroup = [];
    this.filtersService.setInternshipsFilter(this.filters);
  }

  setGroupFilter(group: any) {
    if (!this.filters.Courses) {
      this.selectedGroup = [];
    }
    let courses = group.courses.map(el => el.item.name);
    if (this.selectedGroup && this.selectedGroup.length > 0) {
      let index = this.selectedGroup.indexOf(group.name);
      if (index > -1) {
        this.selectedGroup.splice(index, 1);
        this.filters.Courses = this.filters.Courses.filter(el => courses.indexOf(el) === -1);
      } else {
        this.selectedGroup.push(group.name);
        this.filters.Courses = this.filters.Courses.concat(courses);
      }
    } else {
      this.selectedGroup = [group.name];
      this.filters.Courses = courses;
    }
    this.checkGroupName();
    this.filtersService.setInternshipsFilter(this.filters);
  }

  checkGroupName() {
    this.selectedGroup = [];
    if (this.filters.Courses && this.filters.Courses.length > 0) {
      this.groups.forEach(group => {
        group.courses.forEach(course => {
          if (this.filters.Courses.includes(course.item.name)) {
            if (this.selectedGroup && this.selectedGroup.length > 0) {
              this.selectedGroup.push(group.name);
            } else {
              this.selectedGroup = [group.name];
            }
          }
        });
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeOther(dropdown: any, icon: any) {
    dropdown.classList.toggle('w--open');
    let elements = this.groupFilter.nativeElement.querySelectorAll('.event-dd-second.w-dropdown-list.w--open');
    elements.forEach(el => (el !== dropdown ? el.classList.remove('w--open') : false));
    icon.classList.toggle('rotateUp');
    let icons = this.groupFilter.nativeElement.querySelectorAll('.event-category-dd-icon.w-icon-dropdown-toggle.rotateUp');
    icons.forEach(el => (el !== icon ? el.classList.remove('rotateUp') : false));
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.groupFilter && !this.groupFilter?.nativeElement.contains(target)) {
      this.groupOpen = false;
    }
  }
}
