import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { IActiveLesson, RamStorageService, StorageKeys, Storages, WebStorageService, UserDataHandlerService } from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { IAssigningCourseData, ICourseMaterialExt } from '../common-components/class-courses/interfaces/class-courses.interfaces';
import { TeacherClassCoursesService } from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';
import { DictionaryType, KruzhkiTermsDictionary, StandardTermsDictionary } from '../../landing/base/landing-base.dictionary';
import { UserRoles } from 'app/shared/enums/userroles.enum';

@Injectable({
  providedIn: 'root',
})
export class RootScopeService {
  private classListByTeacher = new ReplaySubject<IAssigningCourseData[]>();
  private currentClass = new ReplaySubject<IAssigningCourseData>(1);
  private userRole: string;
  private dictionary: DictionaryType = StandardTermsDictionary;
  private isTeacher: boolean = false;

  constructor(
    private webStorageService: WebStorageService,
    private ramStorageService: RamStorageService,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private overlayBusy: OverlayBusyService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    if (location.origin.includes('kruzhki')) {
      this.dictionary = KruzhkiTermsDictionary;
    }
  }

  set currentClassInfo(selectedClass: IAssigningCourseData) {
    this.userRole = this.userDataHandlerService.getUserInfo().role;
    this.isTeacher = this.userRole === UserRoles.teacher;

    if (!selectedClass) {
      this.isTeacher ? this.ramStorageService.set(StorageKeys.TeacherHasNoClasses, true) : null;
      return;
    }

    this.isTeacher ? this.ramStorageService.set(StorageKeys.TeacherHasNoClasses, false) : null;
    this.webStorageService.set(StorageKeys.ClassNumber, selectedClass.schoolClass.number);
    this.webStorageService.set(StorageKeys.ClassLetter, selectedClass.schoolClass.letter);

    if (!selectedClass.course || !this.isTeacher || (selectedClass.course as ICourseMaterialExt)?.activeLesson) {
      this.currentClass.next(selectedClass);
      this.ramStorageService.set(StorageKeys.CurrentClassInfo, selectedClass, Storages.Local);
      return;
    }

    let classInfo: IAssigningCourseData = null;

    this.overlayBusy.show();
    this.getCourseMaterialWithPassedData(selectedClass)
      .pipe(
        switchMap(assigningCourseData => {
          classInfo = assigningCourseData;

          return this.teacherClassCoursesService.getActiveLesson(selectedClass.schoolClass?.id || selectedClass.schoolClass);
        }),
        take(1),
      )
      .subscribe(activeLesson => {
        classInfo.activeLesson = activeLesson ?? ({} as IActiveLesson);

        this.currentClass.next(classInfo);
        this.ramStorageService.set(StorageKeys.CurrentClassInfo, classInfo, Storages.Local);
        this.overlayBusy.hide();
      });
  }

  get currentCLass(): Observable<IAssigningCourseData> {
    return this.currentClass.asObservable();
  }

  set classesArray(classes: IAssigningCourseData[]) {
    this.webStorageService.set(StorageKeys.ClassesArray, classes);
    this.classListByTeacher.next(classes);
  }

  get classesList(): Observable<IAssigningCourseData[]> {
    return this.classListByTeacher.asObservable();
  }

  private getCourseMaterialWithPassedData(selectedClass: IAssigningCourseData): Observable<IAssigningCourseData> {
    return this.teacherClassCoursesService
      .getCourseMaterialWithPassedData(
        (selectedClass.course as ICourseMaterialExt)?.id ?? (selectedClass.course as string),
        selectedClass.schoolClass,
      )
      .pipe(map((course: ICourseMaterialExt) => ({ ...selectedClass, course })));
  }

  public getDictionary(): DictionaryType {
    return this.dictionary;
  }
}
