import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'prf-recommended-professions-card',
  standalone: true,
  templateUrl: './recommended-professions-card.component.html',
  styleUrls: ['./recommended-professions-card.component.scss'],
  imports: [
    NgOptimizedImage
  ]
})
export class RecommendedProfessionsCardComponent {
  @Input() public talent: any;
}
