import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef
} from '@angular/core';
import {LoadingHandlerService} from "../../../services/loading-handler/loading-handler.service";

@Component({
  selector: 'prf-mobile-loaders',
  standalone: true,
  templateUrl: './mobile-loaders.component.html',
  styleUrls: ['./mobile-loaders.component.scss'],
})
export class MobileLoadersComponent implements AfterViewInit{
  public template: TemplateRef<any>;

  @ViewChild('loading', { read: ViewContainerRef }) loading: ViewContainerRef;

  constructor(private loadingHandlerService: LoadingHandlerService, private changeDetector: ChangeDetectorRef) {}
  private changeViewContent(view: ViewRef): void {
    this.loading.clear();
    this.loading.insert(view);
  }
 public ngAfterViewInit(): void {
    this.loadingHandlerService.loadingModalTemplate.subscribe(template => {
      if (template) {
        this.template = template;
        this.changeViewContent(this.template.createEmbeddedView(null));
        this.changeDetector.detectChanges();
      }
    });
  }
}
