import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHandlerService } from 'app/ui-kit/services/modal-handler/modal-handler.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'prf-side-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideModalComponent {
  public template: TemplateRef<any>;
  public animate = new BehaviorSubject<string>('');

  @ViewChild('sideModal', { read: ViewContainerRef }) sideModal: ViewContainerRef;

  constructor(private modalHandlerService: ModalHandlerService, private changeDetector: ChangeDetectorRef) {}

  private changeViewContent(view: ViewRef): void {
    this.sideModal.clear();
    this.sideModal.insert(view);
  }

  ngAfterViewInit(): void {
    this.modalHandlerService.sideModalTemplate.subscribe(template => {
      if (template) {
        this.template = template;
        this.changeViewContent(this.template.createEmbeddedView(null));
        this.changeDetector.detectChanges();
      }
    });
    setTimeout(() => this.animate.next('open'), 100);
  }

  public closeModal(): void {
    this.animate.next('close');
    setTimeout(() => {
      this.modalHandlerService.toggleSideModal(false);
    }, 900);
  }
}
