import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InternshipsFiltersService } from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { PageHeaderService } from 'app/shared/dashboard/page-header/page-header.service';
import { MenuService } from '../menu/menu.service';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-employer-menu',
  templateUrl: './employer-menu.component.html',
  styleUrls: ['./employer-menu.component.scss'],
})
export class EmployerMenuComponent implements OnInit {
  @Input() innerRoute: boolean = false;
  @Input() professionActive: boolean = false;
  public href: string;
  public root: string = 'employer';

  public currentUrl: string = '';
  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';

  isMOStend: boolean = false;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private headerService: PageHeaderService,
    private internshipsFiltersService: InternshipsFiltersService,
    private webStorageService: WebStorageService,
  ) {
    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.imagePath = this.webStorageService.get(StorageKeys.ImagePath);
    this.firstName = this.webStorageService.get(StorageKeys.FirstName);
    this.isMOStend = location.origin.includes('mosreg');
  }

  ngOnInit() {
    this.detectUrlChange();
  }

  detectUrlChange() {
    this.currentUrl = this.router.url.split('/')[1];
    this.currentUrl = '/' + this.currentUrl;
    if (this.currentUrl.includes('#')) {
      this.currentUrl = this.router.url.split('#')[0];
    }
  }

  get routeActive(): boolean {
    return this.router.isActive('/employer', true);
  }

  public selectMain() {
    this.hideMobileMenu();
    return this.router.navigate(['/' + this.root]);
  }

  public selectInternships() {
    this.hideProfile();
    this.hideMobileMenu();
    this.internshipsFiltersService.setInternshipsFilter({}, 0);
    this.router.navigate(['/' + this.root + '/internships']);
  }

  public selectExersices() {}

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile() {
    this.headerService.closeProfile();
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/' + this.root + '/profile']);
  }
}
