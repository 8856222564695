import {Component, Input} from '@angular/core';

@Component({
  selector: 'prf-favourite-icon',
  standalone: true,
  templateUrl: './favourite-icon.component.html',
  styleUrls: ['./favourite-icon.component.scss'],
})
export class FavouriteIconComponent {
  @Input() public deleting: boolean = false;
}
