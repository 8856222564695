import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { COURSES_TYPES } from '../../courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { ApiSchoolsService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-user-favorites',
  templateUrl: './user-favorites.component.html',
  styleUrls: ['./user-favorites.component.scss'],
})
export class UserFavoritesComponent extends UnsubscribeComponent implements OnInit {
  @Input() user: any;
  @Input() favoritesList: any;
  courses: any = [];
  events: any = [];
  classes: any = [];
  favoritesMenu = [
    { key: 'all', name: 'Все' },
    { key: 'events', name: 'Мероприятия' },
    { key: 'courses', name: 'Курсы' },
    { key: 'classes', name: 'Предметы' },
  ];

  types: any = COURSES_TYPES;
  currentFilter: any;

  constructor(private apiSchoolsService: ApiSchoolsService) {
    super();
  }

  ngOnInit() {
    this.currentFilter = this.favoritesMenu[0];
    this.getFavoritesEvents();
  }

  setFilter(filter: any) {
    this.currentFilter = filter;
  }

  getFavoritesEvents() {
    const ids = this.favoritesList.filter(el => el.productType === 2).map(el => el.productId);

    const userFavourites$: Observable<any>[] = [];
    ids.forEach(id => {
      userFavourites$.push(
        this.apiSchoolsService.getClassById(id).pipe(
          take(1),
          tap(favourite => {
            if (favourite.classesFormat === 'ShortCourse' || favourite.classesFormat === 'LongCourse') {
              this.courses.push(favourite);
            } else {
              this.events.push(favourite);
            }
          }),
        ),
      );
    });

    forkJoin(userFavourites$).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  trackByFn(index, item) {
    return index;
  }
}
