<div class="prf-login-page">
  <div #pageBody class="prf-login">
    <a routerLink="/" class="prf-login-logo">
      <img src="./profilum-assets/images/eurochem/logo_big.png" alt="EUROCHEM" />
    </a>
    @if (isParent) {
      <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.REGISTRATION.PARENT.INSERT_CHILD_INFO }}</p>
    }
    <ng-container
      *ngTemplateOutlet="isReferralValid ? DEFAULT_TEMPLATE : NOT_VALID_REFERRAL_TEMPLATE"
    ></ng-container>
    @if (isPhoneUsed) {
      <div class="code-input-wrapper">
        <p class="code-input-state-message error-text">
          {{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.PHONE_NUMBER_USED }}
        </p>
      </div>
    }
  </div>
  <prf-page-footer class="prf-footer"></prf-page-footer>
</div>

<ng-template #DEFAULT_TEMPLATE>
  <form [formGroup]="form" [class.display-none]="isVisibleCode">
    <div class="form-wrapper">
      <div class="field">
        <label> Город </label>
        <div class="wrapper">
          <ng-select
            #dropdownCityRef
            class="custom"
            appendTo="body"
            [clearable]="false"
            [searchable]="false"
            notFoundText="Ничего не найдено"
            formControlName="city"
            placeholder="Выберите город"
            (change)="onSelectCity($event)"
            (open)="onOpen()"
            (close)="onClose()"
          >
            @for (city of cities; track city.id) {
              <ng-option class="custom" [value]="city.value"> {{ city.value }} </ng-option>
            }
          </ng-select>
        </div>
      </div>

      <div class="field">
        <label>{{ isParent ? 'Мой ребенок проходит профпробы от' : 'Я прохожу профпробы от' }}</label>
        <div class="wrapper wrapper_option">
          <button type="button" class="option-button" [class.chosen]="eiType === 1" (click)="onClick()">Школа</button>
          <button type="button" class="option-button" [class.chosen]="eiType === 2" (click)="onClick()">Образовательный центр</button>
        </div>
      </div>

      <div class="field" [class.field-disabled]="!isEiEnabled">
        <label> Название учебного заведения </label>
        <div class="wrapper">
          <ng-select
            #dropdownEiRef
            class="custom"
            appendTo="body"
            dropdownPosition="bottom"
            notFoundText="Ничего не найдено"
            [clearable]="false"
            [searchable]="false"
            formControlName="eiName"
            placeholder="Выберите учебное заведение"
            [readonly]="!isEiEnabled"
            [id]="eis"
            (open)="onOpen()"
            (close)="onClose()"
            (change)="onSelectEi($event)"
          >
            @for (eiName of eis; track eiName.value) {
              <ng-option [value]="eiName.value"> {{ eiName.value }} </ng-option>
            }
          </ng-select>
        </div>
      </div>

      <div *ngIf="eiType === 1" class="field">
        <label> Другое название учебного заведения </label>
        <div class="wrapper">
          <input class="custom" appendTo="body" formControlName="other" [id]="other" type="text" />
        </div>
      </div>
    </div>
  </form>

  <prf-form
    #childRegistrationForm
    [configName]="formConfig"
    [acceptButton]="confirmButtonText"
    [isButtonsVisible]="false"
    [class.display-none]="isVisibleCode"
    (submitOpen)="onOpen()"
    (submitClose)="onClose()"
  />
  @if (isVisibleCode) {
    <div class="code-input-wrapper">
      <p class="description">
        {{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.CALL_WILL_SENT_TO_NUMBER }}
        <span class="phone-number">{{ childRegistrationForm.formValue.phone }}</span
        >. {{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.CALL_WILL_SENT_TO_NUMBER_END }}
      </p>
      <prf-code-input #codeInput [codeLength]="4" [currentState]="codeInputFieldState" (codeCompleted)="verifyInputCode($event)" />
      <p *ngIf="codeInputFieldState === CodeInputStateEnum.ERROR" class="code-input-state-message error-text">
        {{ SendCodeErrorsEnum.ERROR }}
      </p>
      <p *ngIf="codeInputFieldState === CodeInputStateEnum.DISABLED" class="code-input-state-message disabled-text">
        {{ SendCodeErrorsEnum.EXCEED_ATTEMPTS }}
      </p>
    </div>
  }
  @if (!isVisibleCode) {
    <prf-button
      [isFullWidth]="true"
      [buttonTitle]="confirmButtonText"
      [type]="ButtonType.Eurochem"
      [disabled]="!childRegistrationForm.isFormValid || childRegistrationForm.isFormPristine"
      (emitClick)="sendCode()"
    />
  }
  @if (isVisibleCode) {
    <prf-button
      class="text-center"
      [disabled]="!!secondsToNextCallRequest"
      [isFullWidth]="true"
      [type]="ButtonType.Eurochem"
      [buttonTitle]="
        EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.REQUEST_NEW_CALL +
        ' ' +
        (secondsToNextCallRequest ? (secondsToNextCallRequest | date: 'mm:ss') : '')
      "
      (emitClick)="sendCode()"
    />
  }
</ng-template>

<ng-template #NOT_VALID_REFERRAL_TEMPLATE>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL1 }}</p>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL2 }}</p>
  <p class="not-valid-referral-text">{{ EUROCHEM_DICTIONARY.PASSWORD_RECOVERY.NOT_VALID_REFERRAL3 }}</p>
</ng-template>
