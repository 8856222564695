import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

import { ApiUsersService, AppSettingsService, IUserInfo, IUserProfileInfo, UserDataHandlerService } from '@profilum-library';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';
import { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { GoalsService } from '../goals.service';
import { ETrajectoryTypes } from '../../../enums/trajectorytypes.enum';
import {
  InternshipsFiltersService,
} from '../../../../pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';

export const MAX_TASKS: number = 3;

@Component({
  selector: 'prf-goals-task-card',
  templateUrl: './goals-task-card.component.html',
  styleUrls: ['./goals-task-card.component.scss'],
})
export class GoalsTaskCardComponent implements OnInit, AfterViewInit {
  @Input() bgColor: string = ''; // [orange, yellow]
  @Input() classesFormat: string = '';
  @Input() coursesFormats: Array<any> = [];
  @Input() complexity: string = '';
  @Input() complexityCourse: string = '';
  @Input() staticTasks: Array<any> = [];
  @Input() text;
  @Input() isStatic: boolean;
  @Input() professionID: string = '';

  @Input() set tasks(val: Array<any>) {
    if (val && val.length > 0) {
      this.taskList = val;

      if (this.staticTasks && this.isStatic) {
        this.taskList = this.staticTasks;
      }

      this.moreTasks = this.taskList.length > MAX_TASKS;
      this.taskList = this.taskList.slice(0, MAX_TASKS);

      if (this.moreTasks && !this.isStatic) {
        this.taskList.push({
          classesFormat: 'ShowMoreTasks',
          cardTitle: this.classesTitleMap[this.taskList[0].classesFormat],
        });
      }
      this.selectByIndex(0);

      if (!this.bgColor) {
        this.bgColor = ['', 'orange', 'yellow'][Math.floor(Math.random() * 3)];
      }
    } else {
      this.taskList = this.staticTasks;
    }
  }

  @Output() eventMenuAccess = new EventEmitter<any>();
  @ViewChild('swiperComponent') swiperComponent?: ElementRef;
  public swiper?: Swiper;
  public userInfo: IUserInfo;
  public staticTask: any;
  public moreTasks: boolean = false;
  public taskList: Array<any> = [];
  public clearTasks: Array<any> = [];
  public currentIndex: number = 0;
  public isShowInfoPopup: boolean = false;
  public isShowTooltip: boolean = false;
  public classesTitleMap = {
    MasterClass: 'Сходи на мастер-класс', // (level-1)
    Excursion: 'Посети экскурсию', // (level-1)
    ShortCourse: 'Запишись на краткосрочный курс', // (level-2)
    LongCourse: 'Запишись на долгосрочный курс',
    Festival: 'Сходи на фестиваль',
    Action: 'Прими участие в акции', // (level-2)
    Meeting: 'Сходи на встречу',
    Competition: 'Прими участие в конкурсе', // (level-2)
    Profproba: 'Познакомься с профессионалом', // (level-1)
    OpenDay: 'Сходи на день открытых дверей', // (level-1)
  };
  public dayjs = DateHelper.dayjs;

  public swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    speed: 300,
    allowTouchMove: true,
  };
  public articleCount: string = '';

  public constructor(
    private router: Router,
    private goalsService: GoalsService,
    private apiUsersService: ApiUsersService,
    private translateService: TranslateService,
    private filtersService: CoursesFiltersService,
    private internshipsFiltersService: InternshipsFiltersService,
    private appSettingsService: AppSettingsService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.userInfo = this.userDataHandlerService.getUserInfo();
  }

  ngOnInit() {
    this.returnCase();
  }

  ngAfterViewInit(): void {
    this.swiper = this.swiperComponent?.nativeElement.swiper;
  }

  public onChangeSlide(slideNum: number) {
    this.currentIndex = slideNum;
  }

  public clickNext() {
    if (this.isEnableNext) {
      this.currentIndex++;
      this.swiper.slideNext();
    }
  }

  public clickPrev() {
    if (this.isEnablePrev) {
      this.currentIndex--;
      this.swiper.slidePrev();
    }
  }

  public selectByIndex(index: number) {
    this.currentIndex = index;

    if (this.swiperComponent) {
      this.swiper.slideTo(this.currentIndex);
    }
  }

  public get isEnableNext(): boolean {
    return this.currentIndex < this.taskList.length - 1;
  }

  public get isEnablePrev(): boolean {
    return this.currentIndex > 0;
  }

  public StaticTaskModal(staticTask) {
    if (staticTask) {
      const staticTaskData = {
        staticTask: staticTask,
        available: true,
      };
      this.goalsService.showStaticTaskModal(staticTaskData);
    }
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  public showMoreCourses() {
    const courseList: Array<string> = ['ShortCourse', 'LongCourse'];

    if (this.taskList && this.taskList.length) {
      this.filtersService.setCoursesFilter({ Courses: this.taskList[0].findCourses });

      if (courseList.some(c => c === this.coursesFormats[0] || this.coursesFormats[1])) {
        this.router.navigate(['/courses']);
      } else {
        this.router.navigate(['/events']);
      }
    }
  }

  checkIsCourses() {
    const courseList: Array<string> = ['ShortCourse', 'LongCourse'];
    if (courseList.some(c => c === this.coursesFormats[0] || this.coursesFormats[1])) {
      return true;
    } else {
      return false;
    }
  }

  private checkClassFormat(taskFormat): boolean {
    return !(taskFormat == 'ShowMoreTasks');
  }

  private setUnitPrice(price): string {
    if (parseInt(price) <= 0) {
      return 'Бесплатно';
    } else {
      return price + this.appSettingsService.getByDefaultLocale('COMMON.MONEY_SYMBOL');
    }
  }

  public setPlan(task) {
    task.active = !task.active;
    let trajectoryType: string = '';
    if (task.classesFormat) {
      trajectoryType = task.classesFormat;
    } else {
      trajectoryType = ETrajectoryTypes.CATALOG_TASK.toString();
    }

    this.apiUsersService
      .addUserTrajectoryPupils(this.userInfo.userId, task.id, trajectoryType, this.professionID)
      .pipe(take(1))
      .subscribe(
        r => {
          this.eventMenuAccess.emit(true);
        },
        err => console.error(err),
      );
  }

  public returnCase() {
    const courseTitles = this.text.story;
    const count = this.taskList.length;
    const title = this.internshipsFiltersService.getTitleByNumber(count, courseTitles);
    this.articleCount = count + ' ' + title;
  }

  private linkToCourse(task) {
    if (task.classesFormat === 'LongCourse' || task.classesFormat === 'ShortCourse') {
      return this.router.navigate(['/courses', task.hrid]);
    } else {
      return this.router.navigate(['/events', task.hrid]);
    }
  }

  public showTooltip(): void {
    if (this.userInfo.role === 'pupil') {
      this.isShowTooltip = true;
    }
  }

  public hideTooltip(): void {
    if (this.userInfo.role === 'pupil') {
      this.isShowTooltip = false;
    }
  }
}
