import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';

import {
  ApiUsersService,
  B2gSaasService,
  IUserInfo,
  IUserProfileInfo,
  UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { TeacherPanelService } from '../../../pages/control-panel/teacher/teacher-panel.service';

@Component({
  selector: 'prf-recommendation-menu',
  templateUrl: './recommendation-menu.component.html',
  styleUrls: ['./recommendation-menu.component.scss'],
})
export class RecommendationMenuComponent extends UnsubscribeComponent {
  @Input()
  set courseId(val: string) {
    this._courseId = val;
    this.init().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  @Input() recommendationType: string = 'Profession'; // [Profession, Class]

  public isOpen: boolean = false;
  public userList: Array<any> = [];
  public userListFiltered: Array<any> = [];
  public userInfo: IUserInfo;

  public selectedAll: boolean = false;
  public searchText: string = '';
  public isRecommended: boolean = false;

  private _courseId: string = '';

  @ViewChild('recommendationMenu') recommendationMenu: ElementRef;

  get selectedAny(): boolean {
    return this.userList.some(u => u._recommended === true && u.recommended === false);
  }

  get recommendedAll(): boolean {
    if (this.userList && this.userList.length > 0) {
      return !this.userList.some(u => u.recommended === false);
    } else {
      return false;
    }
  }

  constructor(
    private teacherPanelService: TeacherPanelService,
    private apiUsersService: ApiUsersService,
    private b2gSaasService: B2gSaasService,
    private yandexMetricsService: YandexMetricsService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.userInfo = this.userDataHandlerService.getUserInfo();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.recommendationMenu && !this.recommendationMenu?.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  public init(): Observable<any> {
    let currentObservable$: Observable<any>;
    if (this.userInfo.role === 'teacher') {
      currentObservable$ = this.apiUsersService.getPupilsByTeacher().pipe(
        switchMap(data => {
          this.userList = data.filter(child => child.isActivated)
            .map(child => {
            child._recommended = false;
            child.recommended = false;
            child.imagePath = child.imagePath ? child.imagePath : './profilum-assets/images/icons/no-photo.svg';
            return child;
          });
          this.userListFiltered = this.userList;
          return this.apiUsersService.getUserIdsWithRecommendationTeachers(this._courseId, this.recommendationType).pipe(
            tap(userIdList => {
              this.userList = this.userList.map(user => {
                const recommended = userIdList.some(id => id === user.userId);
                user._recommended = recommended;
                user.recommended = recommended;
                return user;
              });
            }),
          );
        }),
      );
      return currentObservable$;
    } else if (this.userInfo.role === 'parent') {
      currentObservable$ = this.b2gSaasService.getUserInfo().pipe(
        tap(data => {
          if (data.children && data.children.length > 0) {
            this.userList = data.children.map(child => {
              child._recommended = false;
              child.recommended = false;
              child.imagePath = child.imagePath ? child.imagePath : './profilum-assets/images/icons/no-photo.svg';
              return child;
            });

            this.userListFiltered = this.userList;
          }
        }),
      );
    } else {
      currentObservable$ = of(null);
    }

    return currentObservable$.pipe(
      switchMap(r => {
        const params = {
          productId: this._courseId,
          recommendationType: this.recommendationType,
        };
        return this.apiUsersService.getUserIdsWithRecommendationParents(params).pipe(
          tap(userIdList => {
            this.userList = this.userList.map(user => {
              const recommended = userIdList.some(id => id === user.userId);
              user.recommended = recommended;
              user._recommended = recommended;
              return user;
            });
          }),
        );
      }),
    );
  }

  public onInputSearchText(e: any) {
    this.searchText = e.target.value.toLowerCase();

    this.userListFiltered = this.userList.filter(user => {
      const name = (user.lastName + ' ' + user.firstName + ' ' + user.middleName).toLowerCase();
      return name.indexOf(this.searchText) > -1;
    });
  }

  public clearSearch(input: any) {
    input.value = '';
    this.searchText = '';
    this.userListFiltered = this.userList;
  }

  public openRecommendationMenu(): void {
    this.isOpen = !this.isOpen;

    switch (this.recommendationType) {
      case 'Profession':
        this.yandexMetricsService.reachGoal(YmItems.T_Professions_Recommendation);
        break;
      case 'Class':
        this.yandexMetricsService.reachGoal(YmItems.T_Courses_Recommendation);
        break;
      default:
        break;
    }
  }

  public onCheckAll() {
    this.selectedAll = !this.selectedAll;
    this.userList.forEach((child, index) => {
      if (this.userList[index].recommended === false) {
        this.userList[index]._recommended = this.selectedAll;
      }
    });
  }

  public onCheckChild(user: any) {
    let index = null;

    this.userList.forEach((u, i) => {
      if (u === user && u.recommended === false) {
        index = i;
      }
    });

    if (index !== null) {
      this.userList[index]._recommended = !this.userList[index]._recommended;
    }
  }

  public recommendProfession() {
    if (this.userList && this.userList.length > 0 && this.selectedAny) {
      const userRecommendations$: Observable<any>[] = [];
      this.userList.forEach(child => {
        if (child._recommended === true) {
          if (this.userInfo.role === 'parent') {
            const params = {
              UserId: child.userId,
              ProductId: this._courseId,
              RecommendationType: this.recommendationType,
              AddresserRole: 'Parent',
            };
            userRecommendations$.push(this.apiUsersService.addUserRecommendationParents(params));
          }
          if (this.userInfo.role === 'teacher') {
            userRecommendations$.push(
              this.apiUsersService.addUserRecommendationTeachers(child.userId, this._courseId, this.recommendationType, 'Teacher'),
            );
          }
          child.recommended = true;
          this.isRecommended = true;
        }
      });

      observableForkJoin(userRecommendations$)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          switch (this.recommendationType) {
            case 'Profession':
              this.yandexMetricsService.reachGoal(YmItems.T_Professions_SendRecommendation);
              break;
            case 'Class':
              this.yandexMetricsService.reachGoal(YmItems.T_Courses_SendRecommendation);
              break;
            default:
              break;
          }

          this.isOpen = false;
        });
    }
  }
}
