import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsDataPresentationModule } from '../results-data-presentation/results-data-presentation.module';
import { DiagnosticResultsComponent } from './diagnostic-results.component';
import { DiagnosticResultsAccordionComponent } from './diagnostic-results-accordion/diagnostic-results-accordion.component';

@NgModule({
  declarations: [DiagnosticResultsComponent, DiagnosticResultsAccordionComponent],
  imports: [CommonModule, ResultsDataPresentationModule],
  exports: [DiagnosticResultsComponent],
})
export class DiagnosticResultsModule {}
