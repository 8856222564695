import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { IActiveLesson, ILessons, ISchoolClass, RamStorageService, StorageKeys, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { IAssigningCourseData, ICourseMaterialExt } from 'app/shared/common-components/class-courses/interfaces/class-courses.interfaces';
import { RootScopeService } from 'app/shared/services/root-scope.service';
import { MOSRED_ID } from '../../../interfaces/regions-id.interface';
import { TeacherClassCoursesService } from 'app/pages/control-panel/teacher/teacher-class-courses/teacher-class-courses.service';

@Component({
  selector: 'prf-class-courses-chapters',
  templateUrl: './class-courses-chapters.component.html',
  styleUrls: ['./class-courses-chapters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassCoursesChaptersComponent extends UnsubscribeComponent implements OnInit {
  public hasPassedLessons: boolean;
  public showChapters: boolean = false;
  public chapters: [string, ILessons][];
  public hasPupils: boolean = false;
  private courseData: IAssigningCourseData;
  private mosRegId: string = MOSRED_ID;
  @Input() private isActive: boolean = false;
  @Input() private goToLesson: Observable<IActiveLesson>;
  @Input() private set chaptersData(chaptersData: [string, ILessons][]) {
    this.showChapters = !!chaptersData?.length;

    if (this.showChapters) {
      this.chapters = chaptersData;
      this.changeDetectorRef.detectChanges();
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private teacherClassCoursesService: TeacherClassCoursesService,
    private ramStorageService: RamStorageService,
    private rootScopeService: RootScopeService,
    private webStorageService: WebStorageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.goToLesson) {
      this.goToLesson.pipe(this.unsubscribeOperator).subscribe((activeLesson: IActiveLesson) => {
        if (activeLesson) {
          this.goToActiveLesson(activeLesson.chapterIndex);
        }
      });
    }

    this.rootScopeService.currentCLass
      .pipe(
        filter((v: IAssigningCourseData) => v !== null),
        this.unsubscribeOperator,
      )
      .subscribe((courseData: IAssigningCourseData) => {
        this.hasPassedLessons = this.teacherClassCoursesService.hasPassedLesson(courseData.course as ICourseMaterialExt);
        this.courseData = courseData;
        this.hasPupils = ((courseData.schoolClass as ISchoolClass)?.pupilsCount ?? 0) > 0;
      });
  }

  public setLessonsInfo(lessons: ILessons): string {
    const { minNumber, maxNumber }: ILessons = lessons;

    return minNumber === maxNumber ? `Занятие ${minNumber}` : `Занятия ${minNumber}-${maxNumber}`;
  }

  public trackByFn(index: number): number {
    return index;
  }

  public goToLessons(currentIndex: number): void {
    const chapterData: [string, ILessons] = this.chapters[currentIndex];

    this.rootScopeService.currentCLass
      .pipe(
        filter((v: IAssigningCourseData) => v !== null),
        take(1),
        this.unsubscribeOperator,
      )
      .subscribe((courseData: IAssigningCourseData) => {
        const schoolClass: ISchoolClass = courseData.schoolClass as ISchoolClass;
        const course: ICourseMaterialExt = courseData.course as ICourseMaterialExt;

        if (schoolClass?.id && course?.id) {
          this.teacherClassCoursesService.setChaptersData(course.id, schoolClass.id, currentIndex, chapterData, this.chapters);
        }

        this.timer = window.setTimeout(() => {
          this.router.navigate(['/class-courses-lessons']);
        }, 500);
      });
  }

  public checkIsActiveChapter(index: number): boolean {
    const regionID: string = this.webStorageService.get(StorageKeys.RegionId);
    const countEnableChapters: number = this.teacherClassCoursesService.countOfActiveChapters;

    if (regionID === this.mosRegId && index < countEnableChapters + 1) {
      return true;
    }

    if (index < countEnableChapters) {
      return true;
    }
  }

  private goToActiveLesson(currentIndex: number): void {
    const index: number = currentIndex === -1 ? 0 : currentIndex > this.chapters.length - 1 ? this.chapters.length - 1 : currentIndex;

    this.goToLessons(index);
  }
}
