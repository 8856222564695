import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NoAutocompleteDirective } from './directives/no-autocomplete.directive';
import { CustomCheckBoxComponent } from './elements/custom-check-box/custom-check-box.component';
import { GenderToggleComponent } from './elements/gender-toggle/gender-toggle.component';
import { MaterialModule } from './modules/material.module';
import { OverlayBusyComponent } from './overlay-busy/overlay-busy.component';
import { DropdownComponent } from './elements/dropdown/dropdown.component';
import { ProgressCircleComponent } from './interactive/progress-circle/progress-circle.component';
import { ProgressCircleBarComponent } from './interactive/progress-circle/progress-circle-bar/progress-circle-bar.component';
import { EducationCircleBarComponent } from './interactive/progress-circle/education-circle-bar/education-circle-bar.component';
import { ChartTabsComponent } from './interactive/chart-tabs/chart-tabs.component';
import { TopListComponent } from './top-list/top-list.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedBaseModule } from './shared-base.module';
import { DropdownWithSearchComponent } from './elements/dropdown-with-search/dropdown-with-search.component';
import { DirectorReportComparisonComponent } from './interactive/director-report-comparison/director-report-comparison.component';
import { DropdownNewComponent } from './elements/dropdown-new/dropdown-new.component';
import { BannerComponent } from './elements/banner/banner.component';
import { RouterModule } from '@angular/router';
import { DropdownSortingComponent } from './elements/dropdown-sorting/dropdown-sorting.component';
import { BasicBannerComponent } from './banners/basic-banner/basic-banner.component';
import { BaseModalComponent } from './modal/base-modal/base-modal.component';
import { ClassTestResultsComponent } from 'app/shared/sidebars/class-test-results/class-test-results.component';
import { ClassWidgetModule } from './common-components/class-widget/class-widget.module';
import { ResetPasswordPopupComponent } from './common-components/reset-password-popup/reset-password-popup.component';
import { NgOptimizedImage } from '@angular/common';
import { ProfessionTryingOnStepsComponent } from './common-components/profession-trying-on-steps/profession-trying-on-steps.component';
import { BaseChartDirective } from 'ng2-charts';
import { TooltipComponent } from '@profilum-components/tooltip/tooltip.component';
import { TippyDirective } from '@ngneat/helipopper';
import { SpiderChartCustomComponent } from '@profilum-components/spider-chart-custom/spider-chart-custom.component';

@NgModule({
  imports: [
    SharedBaseModule,
    DashboardModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    ClassWidgetModule,
    NgOptimizedImage,
    BaseChartDirective,
    TooltipComponent,
    TippyDirective,
    SpiderChartCustomComponent,
  ],
  declarations: [
    NoAutocompleteDirective,
    ClassTestResultsComponent,
    OverlayBusyComponent,
    GenderToggleComponent,
    CustomCheckBoxComponent,
    DropdownComponent,
    ProgressCircleComponent,
    ProgressCircleBarComponent,
    EducationCircleBarComponent,
    ChartTabsComponent,
    TopListComponent,
    DropdownWithSearchComponent,
    DropdownNewComponent,
    DirectorReportComparisonComponent,
    BannerComponent,
    DropdownSortingComponent,
    BasicBannerComponent,
    BaseModalComponent,
    ResetPasswordPopupComponent,
    ProfessionTryingOnStepsComponent,
  ],
  exports: [
    // т.к. в shared есть другие подмодули, в которых приходится подключать тот же список основных модулей,
    // вынесла их в отдельный модуль SharedBaseModule. Получается зависимость SharedBaseModule <- SharedModule <- PagesModule
    SharedBaseModule,
    DashboardModule,
    NoAutocompleteDirective,
    OverlayBusyComponent,
    GenderToggleComponent,
    CustomCheckBoxComponent,
    DropdownComponent,
    ProgressCircleComponent,
    ProgressCircleBarComponent,
    ChartTabsComponent,
    TopListComponent,
    DropdownWithSearchComponent,
    DropdownNewComponent,
    BannerComponent,
    DropdownSortingComponent,
    BasicBannerComponent,
    BaseModalComponent,
    ClassTestResultsComponent,
    ResetPasswordPopupComponent,
    ProfessionTryingOnStepsComponent,
  ],
  providers: [NoAutocompleteDirective],
})
export class SharedModule {}
