import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isArray } from 'util';
import { Observable, of, timer } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { EventEmitter } from 'events';

export enum DDType {
  FULL,
  SHORT,
}

@Component({
  selector: 'prf-dropdown-with-search',
  templateUrl: './dropdown-with-search.component.html',
  styleUrls: ['./dropdown-with-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownWithSearchComponent),
      multi: true,
    },
  ],
})
export class DropdownWithSearchComponent {
  @Input() label: string;
  @Input() isError: boolean;
  @Input() type: DDType = DDType.FULL;
  @Input() set disabledInput(value: boolean) {
    if (value) {
      this._isDisabled = value;
    }
    this._isDisabled ? this.control.disable() : null;
  }
  get disabledInput() {
    return this._isDisabled;
  }
  @Input()
  set options(data: { name: string | number; data: any }[]) {
    if (isArray(data)) {
      data = data.sort((a, b) => {
        if (Number.isInteger(+a.name) && Number.isInteger(+b.name)) {
          return +a.name - +b.name;
        }
        let nameA = String(a.name).toLowerCase(),
          nameB = String(b.name).toLowerCase();
        return nameA.localeCompare(nameB);
      });
    }
    this._options = isArray(data) ? data : this._options;
    this.cd.markForCheck();
  }
  // @Input() enabled: boolean = true;

  @ViewChild('dropdownContent') dropdownContent: ElementRef;
  dropdownToggled = new EventEmitter();

  _options: { name: string | any; data: any }[] = [];
  _currentControl: any = null;
  _isDisabled: boolean = false;
  control = new UntypedFormControl();
  filteredOptions: Observable<{ name: string; data: any }[]>;
  isFind: boolean = false;
  DDType = DDType;

  @HostListener('document:click', ['$event.target'])
  clickOut(target) {
    this.isFind = false;
  }

  constructor(private eRef: ElementRef, private cd: ChangeDetectorRef) {}

  propagateChange = (control: any) => {};
  propagateTouch = (control: any) => {};
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  writeValue(value: any): void {
    const valueView = value && value.hasOwnProperty('name') ? value.name : value;

    this.control.setValue(valueView);
    this.control.updateValueAndValidity();
    this.filteredOptions = of(this._options);
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((formValue: string) => this._filter(this.control.value ? this.control.value : formValue)),
    );
    this.currentControl = value;
    this.cd.markForCheck();
  }

  get currentControl() {
    return this._currentControl;
  }
  set currentControl(control: any) {
    this._currentControl = control;
    this.propagateChange(control);
    this.propagateTouch(control);
  }

  private _filter(value: string): { name: string; data: any }[] {
    const filterValue = value ? String(value).toLowerCase() : value;

    const res: any = this._options.filter(option => String(option.name).toLowerCase().includes(filterValue));
    return res;
  }

  public toggleInput(event) {
    if (!this.disabledInput) {
      // закрывает все другие открытые <prf-dropdown-with-search> на странице
      let elements = document.getElementsByClassName('w-dropdown-content');
      let elementsArray = Array.from(elements);
      elementsArray.forEach((el, index) => {
        if (elements.item(index) != this.dropdownContent.nativeElement) {
          let element = elements.item(index) as HTMLElement;
          element.classList.contains('opened') ? element.click() : null;
        }
      });

      this.dropdownToggled.emit('true');
      this.isFind = !this.isFind;
      if (this.isFind) {
        this.writeValue('');
      }
      event.stopPropagation();
    }
  }

  public resetWithTimeout(timeout, callback) {
    this.control.reset();

    if (callback) {
      timer(timeout)
        .pipe(take(1))
        .subscribe(() => {
          callback();
          this.writeValue('');
        });
    }
  }
}
