import { HostListener, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageHeaderService } from '../../page-header/page-header.service';
import { MenuService } from '../menu/menu.service';
import { StorageKeys, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-tutor-menu',
  templateUrl: './tutor-menu.component.html',
  styleUrls: ['./tutor-menu.component.scss'],
})
export class TutorMenuComponent implements OnInit {
  @Input() innerRoute: boolean = false;
  public root: string = 'tutor';
  public currentUrl: string;

  public isShowMobileMenu: boolean = false;
  public imagePath: string = '';
  public firstName: string = '';

  isMOStend: boolean = false;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private headerService: PageHeaderService,
    private webStorageService: WebStorageService,
  ) {
    this.menuService.showMobileMenu$.subscribe(show => {
      this.isShowMobileMenu = show;
    });
    this.imagePath = this.webStorageService.get(StorageKeys.ImagePath);
    this.firstName = this.webStorageService.get(StorageKeys.FirstName);
    this.isMOStend = location.origin.includes('mosreg');

    this.currentUrl = this.router.url.split('/')[1];
    this.currentUrl = '/' + this.currentUrl;
    if (this.currentUrl.includes('#')) {
      this.currentUrl = this.router.url.split('#')[0];
    }
  }

  ngOnInit() {}

  get routeActive(): boolean {
    return this.router.isActive('/tutor', true);
  }

  public selectMain() {
    this.hideProfile();
    this.hideMobileMenu();
    return this.router.navigate(['/' + this.root]);
  }

  public selectCourses() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/courses']);
  }

  public selectProfile() {
    this.hideProfile();
    this.hideMobileMenu();
    this.router.navigate(['/' + this.root + '/profile']);
  }

  public hideMobileMenu() {
    this.menuService.showMobileMenu(false);
  }

  public hideProfile() {
    this.headerService.closeProfile();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if (this.isShowMobileMenu) {
      this.isShowMobileMenu = false;
    }
  }
}
