import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

import { AppSettingsService, LocationEnum, RamStorageService, routeAnimations } from '@profilum-library';
import { StorageKeys, Storages, WebStorageService } from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

import { RootScopeService } from '../shared/services/root-scope.service';
import { MenuItems } from 'app/shared/enums/menu.enums';
import { UserRoles } from 'app/shared/enums/userroles.enum';

@Component({
  selector: 'prf-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [routeAnimations],
})
export class PagesComponent extends UnsubscribeComponent implements OnInit {
  public hideHeader = false;
  public activeLocation: LocationEnum = LocationEnum.BASE;
  public userRole: string = '';
  private isProfessionTestPage: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appSettings: AppSettingsService,
    private ramStorageService: RamStorageService,
    private rootScopeService: RootScopeService,
    private webStorageService: WebStorageService,
  ) {
    super();
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(e => {
      if (e instanceof NavigationStart) {
        this.ramStorageService.set(StorageKeys.SelectedMenuItem, null);
      }

      if (e instanceof NavigationEnd) {
        this.isProfessionTestPage = e.url.includes('test-na-professiyu');
      }
    });
    this.activeLocation = this.appSettings.currentLocation;
    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.unsubscribe),
      )
      .forEach(() => {
        this.hideHeader = route.root.firstChild.snapshot.data['hideHeader'];
        this.userRole = this.webStorageService.get(StorageKeys.UserRole);
      });
  }

  ngOnInit(): void {
    this.rootScopeService.currentClassInfo = this.webStorageService.get(StorageKeys.CurrentClassInfo);

    this.ramStorageService
      .get(StorageKeys.SelectedMenuItem, Storages.Local)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selectedMenuItem => {
        if (this.webStorageService.get(StorageKeys.UserRole) === UserRoles.teacher && selectedMenuItem === MenuItems.Results) {
          document.body.classList.add('body-white');
        } else {
          document.body.classList.remove('body-white');
        }
      });
  }
}
