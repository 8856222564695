import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PrEventsEnums } from 'app/shared/enums/pr-events.enums';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { Observable, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { RegistrationBase } from '../../../../../shared/auth-classes/registration.base';
import { FilterSchoolsPipe } from '../../../../../shared/pipes/filter-schools.pipe';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiAuthService,
  ApiLocationsService,
  ApiSchoolsService,
  AppSettingsService,
  B2gSaasService,
  IUserInfo,
  IUserMurmanskInterface,
  StorageKeys, UserDataHandlerService,
  WebStorageService,
  YandexMetricsService,
  YmItems,
} from '@profilum-library';
import { UtilsService } from '../../../../../shared/dashboard/backend-services/utils.service';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Component({
  selector: 'prf-open-registration-murmansk',
  templateUrl: './open-registration-murmansk.component.html',
  styleUrls: ['./open-registration-murmansk.component.scss'],
})
export class OpenRegistrationMurmanskComponent extends RegistrationBase<B2gSaasService> implements OnInit {
  @Input() userData: IUserMurmanskInterface;
  public personalTerms: boolean = false;
  public advertisingTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public mask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public isKz: boolean = true;
  public SPUserRegistration: boolean = false;

  constructor(
    registrationService: B2gSaasService,
    router: Router,
    fb: UntypedFormBuilder,
    apiLocationsService: ApiLocationsService,
    apiSchoolsService: ApiSchoolsService,
    filterSchoolsPipe: FilterSchoolsPipe,
    filterByNamePipe: FilterByNamePipe,
    translateService: TranslateService,
    appSettingsService: AppSettingsService,
    b2gSaasService: B2gSaasService,
    userDataHandlerService: UserDataHandlerService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    protected webStorageService: WebStorageService,
    protected userStorageService: UserStorageService,
    protected yandexMetricsService: YandexMetricsService,
    private apiAuthService: ApiAuthService,
    protected utilsService: UtilsService,
  ) {
    super(
      userDataHandlerService,
      yandexMetricsService,
      registrationService,
      router,
      fb,
      apiLocationsService,
      apiSchoolsService,
      filterSchoolsPipe,
      filterByNamePipe,
      translateService,
      appSettingsService,
      b2gSaasService,
      webStorageService,
      userStorageService,
      utilsService,
      'parent',
    );
    const componentName: MetaDefinition = {
      name: 'profliner:title',
      content: 'Open Registration',
    };
    this.meta.addTag(componentName);
  }

  async ngOnInit() {
    await super.ngOnInit();
    if (this.userData != null) {
      // SchoolPortal registration
      this.SPUserRegistration = true;
      this.setUserDataValues();
    } else {
      // open registration
      this.SPUserRegistration = false;
      this.setEmptyValues();
      if (!this.tag) {
        this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
          this.tag = params.tag;
        });
        // Check if we have this tag type in system
        if (this.tag != PrEventsEnums.Emailing.toString()) {
          this.tag = null;
        }
      }
    }

    this.defaultRegion = this.userData.regionId;

    // Check Tag in URL
    if (!this.tag) {
      this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
        this.tag = params.tag;
      });
      // Check if we have this tag type in system
      if (this.tag != PrEventsEnums.Emailing.toString()) {
        this.tag = null;
      }
    }
  }

  private setEmptyValues() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      city: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(this.passwordRegExp)
      ]),
      role: new UntypedFormControl(this.role, [Validators.required]),
    });
  }

  getRegionCities(): Observable<any> {
    return this.apiLocationsService.getRegionCities(this.defaultRegion).pipe(tap(r => (this.cities = r)));
  }

  public registerBase() {
    this.submitted = true;
    if (this.form.valid) {
      const credentials = {
        userName: this.f.email.value,
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        password: this.f.password.value,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        regionId: this.defaultRegion,
        municipalityId: this.f.city.value.data.municipalityId,
        city: this.f.city.value,
        role: this.f.role.value,
        tag: this.tag,
        isConsentToMailing: this.advertisingTerms,
        IsAcceptPersonalInformation: this.personalTerms,
      };
      this.registrationFailed = false;
      this.passFailed = false;
      this.duplicateUserName = false;
      this.registrationService
        .openregistration(credentials)
        .pipe(
          switchMap((response: any) => {
            if (response) {
              if (response.userId == undefined || response.userId == null) {
                this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                this.registrationFailed = true; // ошибка регистрации
                this.failWaiting();
                return of(null);
              } else {
                if (this.tag) {
                  this.yandexMetricsService.reachGoal(YmItems.PARTNER_EMAIL_REGISTRATION);
                }

                this.yandexMetricsService.reachGoal(YmItems.PARENT_REG);

                // Сразу попадаем в ЛК
                return this.apiAuthService.loginSP(response.userId, response.email).pipe(
                  take(1),
                  switchMap((loginResult: any) => {
                    if (loginResult) {
                      if (loginResult.succeeded === false) {
                        this.loginFailed = true;
                        this.passFailed = true;
                        return of(null);
                      } else {
                        this.userStorageService.setUserId = loginResult.userId;
                        this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                        this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                        this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                        this.webStorageService.set(StorageKeys.IsAuthorized, true);
                        return this.registrationService.getAccess(loginResult.userId).pipe(
                          switchMap((accessResponse: any) => {
                            this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                            this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);
                            switch (loginResult.role) {
                              case 'parent': {
                                return this.router.navigate(['/parent']);
                              }
                            }
                          }),
                        );
                      }
                    } else return of(null);
                  }),
                );
              }
            } else return of(null);
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(_ => this.removeWaiting());
    } else {
      this.failWaiting();
    }
  }

  public registerSchoolPortal() {
    // регистрация родителя через ШП
    this.submitted = true;
    if (this.personalTerms && (this.form.valid || this.form.disabled)) {
      const SPCredentials = {
        externalAppUserId: this.userData.externalAppUserId,
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        role: this.f.role.value,
        regionId: this.defaultRegion,
        municipalityId: this.f.city.value.municipalityId,
        city: this.f.city.value.name,
        isConsentToMailing: this.advertisingTerms,
      };
      this.registrationFailed = false;
      this.passFailed = false;
      this.duplicateUserName = false;
      this.apiAuthService
        .registrationSP(SPCredentials)
        .pipe(
          switchMap((response: any) => {
            if (!response || response.userId == undefined || response.userId == null) {
              this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
              this.registrationFailed = true; // ошибка регистрации
              this.failWaiting();
              return of(null);
            } else {
              if (this.tag) {
                this.yandexMetricsService.reachGoal(YmItems.PARTNER_EMAIL_REGISTRATION);
              }

              this.yandexMetricsService.reachGoal(YmItems.PARENT_REG);

              // Сразу попадаем в ЛК
              return this.apiAuthService.loginSP(response.userId, SPCredentials.email).pipe(
                take(1),
                switchMap((loginResult: any) => {
                  if (!loginResult || loginResult.succeeded === false) {
                    this.loginFailed = true;
                    this.passFailed = true;
                    this.removeWaiting();
                    return of(null);
                  } else {
                    this.userStorageService.setUserId = loginResult.userId;
                    this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                    this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                    this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                    this.webStorageService.set(StorageKeys.IsAuthorized, true);
                    return this.registrationService.getAccess(loginResult.userId).pipe(
                      tap((accessResponse: any) => {
                        this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                        this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);
                              this.setUserInfoInLS();

                            // Сразу попадаем в ЛК
                            switch (loginResult.role) {
                              case 'parent': {
                                return this.router.navigate(['/parent']);
                              }
                            }
                            this.removeWaiting();
                      }),
                    );
                  }
                }),
              );
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else {
      this.failWaiting();
    }
  }

  public setUserDataValues() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl({ value: this.userData.lastName, disabled: this.userData.lastName ? true : false }, [
        Validators.required,
      ]),
      firstName: new UntypedFormControl({ value: this.userData.firstName, disabled: this.userData.firstName ? true : false }, [
        Validators.required,
      ]),
      middleName: new UntypedFormControl({ value: this.userData.middleName, disabled: this.userData.middleName ? true : false }, [
        Validators.required,
      ]),
      email: new UntypedFormControl({ value: this.userData.email, disabled: this.userData.email ? true : false }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      phone: new UntypedFormControl(null, [Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      role: new UntypedFormControl('parent', [Validators.required]),
      city: new UntypedFormControl({ value: null, disabled: false }, [Validators.required]),
    });
  }

  public setLanguage() {
    const language: string = this.webStorageService.get(StorageKeys.SelectedLanguage);
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz';
      this.translateService.use(language);
    }
    return;
  }

  protected initFormGroup() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      region: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [Validators.required]),
      role: new UntypedFormControl(this.role, [Validators.required]),
    });
  }

  get isClassSelected() {
    return true;
  }

  public submit() {
    this.submitted = true;
    if (this.form.valid) {
      const credentials = {
        userName: this.f.email.value,
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        password: this.f.password.value,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        regionId: this.f.city.value.regionId,
        municipalityId: this.f.city.value.municipalityId,
        city: this.f.city.value.data.name,
        role: this.f.role.value,
        tag: this.tag,
        isConsentToMailing: this.advertisingTerms,
        IsAcceptPersonalInformation: this.personalTerms,
      };
      this.registrationFailed = false;
      this.passFailed = false;
      this.duplicateUserName = false;
      this.registrationService
        .openregistration(credentials)
        .pipe(
          switchMap((response: any) => {
            if (!response || response.userId == undefined || response.userId == null) {
              this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
              this.registrationFailed = true; // ошибка регистрации
              this.failWaiting();
              return of(null);
            } else {
              if (this.tag) {
                this.yandexMetricsService.reachGoal(YmItems.PARTNER_EMAIL_REGISTRATION);
              }

              this.yandexMetricsService.reachGoal(YmItems.PARENT_REG);

              // Сразу попадаем в ЛК
              return this.apiAuthService.loginSP(response.userId, response.email).pipe(
                switchMap((loginResult: any) => {
                  if (!loginResult || loginResult.succeeded === false) {
                    this.loginFailed = true;
                    this.passFailed = true;
                    this.removeWaiting();
                    return of(null);
                  } else {
                    this.userStorageService.setUserId = loginResult.userId;
                    this.webStorageService.set(StorageKeys.UserRole, loginResult.role);
                    this.webStorageService.set(StorageKeys.UserId, loginResult.userId);
                    this.webStorageService.set(StorageKeys.Tag, loginResult.tag);
                    this.webStorageService.set(StorageKeys.IsAuthorized, true);
                    return this.registrationService.getAccess(loginResult.userId).pipe(
                      tap((accessResponse: any) => {
                        this.webStorageService.set(StorageKeys.UserAccess, accessResponse.type);
                        this.webStorageService.set(StorageKeys.Issuer, accessResponse.issuer);

                        this.setUserInfoInLS();

                            switch (loginResult.role) {
                              case 'parent': {
                                return this.router.navigate(['/parent']);
                              }
                            }

                            this.removeWaiting();
                      }),
                    );
                  }
                }),
              );
            }
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    } else {
      this.failWaiting();
    }
  }

  setUserInfoInLS() {
    const ui: IUserInfo = this.userDataHandlerService.getUserInfo();

    if (!ui) {
      throw 'User info is not defined';
    }

    this.webStorageService.set(StorageKeys.ImagePath, ui.imagePath);
    this.webStorageService.set(StorageKeys.FirstName, ui.firstName);
    this.webStorageService.set(StorageKeys.LastName, ui.lastName);
    this.webStorageService.set(StorageKeys.SchoolId, ui.schoolId);
    this.webStorageService.set(StorageKeys.UserGender, ui.gender);
    this.webStorageService.set(StorageKeys.CompanyId, ui.companyId);
    this.webStorageService.set(StorageKeys.Position, ui.position);
    this.webStorageService.set(StorageKeys.RegionId, ui.regionId);
    this.webStorageService.set(StorageKeys.MunicipalityId, ui.municipalityId);
    this.webStorageService.set(StorageKeys.City, ui.city);
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    if (this.SPUserRegistration) {
      this.registerSchoolPortal();
    } else {
      this.registerBase();
    }
  }

  get isAccessAllowed() {
    if (this.SPUserRegistration) {
      return this.form.valid && this.personalTerms;
    } else {
      return this.form.valid && this.isValidPassword(this.f.password.value);
    }
  }
}
