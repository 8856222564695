import { Location } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiPlayerService,
  ApiProfilesService,
  ApiResultsService,
  ApiSearchService,
  AppSettingsService,
  B2gSaasService,
  EmptyGuid,
  IProfession,
  ITestInfo,
  IUserInfo,
  IUserProfileInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { ETestTypes } from 'app/shared/enums/testtypes.enum';
import {
  IInterestsAndSkillsText,
} from '../../shared/common-components/prf-tests/interests-and-skills/interests-and-skills.interface';
import { IResult, IResultsData } from 'app/pages/results/results.interface';
import { ResultsService } from 'app/pages/results/results.service';
import { SpiderChartFiguresEnum, SpiderChartThemeEnum } from '@profilum-components/spider-chart-custom/spider-chart-custom.model';

export const shortDescriptionLen: number = 40;
@Component({
  selector: 'prf-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnDestroy {
  @Input()
  set sessionId(val: string) {
    this._sessionId = val;

    this.apiResultsService
      .getResultsPage([this._sessionId])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((results: any) => {
        this.resultsEmitter.emit(results);
        this.parseResults(results);
      });
  }

  @Input() child: any = {
    userId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    registrationCode: '',
    schoolClassId: '',
    schoolId: '',
    role: '',
  };

  @Input() showSwitch: boolean = false;
  @Input() showResultMenu: boolean = false;
  @Input() showOnlyParentResults: boolean = false;

  @Output() loaded = new EventEmitter<boolean>();
  @Output() pupilSessionComplete = new EventEmitter<boolean>();
  @Output() parentSessionComplete = new EventEmitter<boolean>();
  @Output() pupilSessionStart = new EventEmitter<boolean>();

  public resultMenu: string = 'results';
  public skillsAttainmentData = [];
  public interestsData = [];
  public talentsData = [];
  public localization: any;
  public fieldsData = [];
  public selectedField: any;
  public activeProfessionList: IProfession[] = [];
  public showParent: boolean = false;
  public showPupil: boolean = false;
  public parentSessionId: string = '';
  public pupilSessionId: string = '';
  public isCompleteTestPupil: boolean = false;
  public isCompleteTestParent: boolean = false;
  public isStartTestPupil: boolean = false;
  public openResults: boolean = false;

  public userInfo: IUserInfo;
  public parentLink: string;
  public dataLoaded: boolean = false;
  public nullResults: boolean = false;
  public skillsAttainment: IResult[] = [];
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public professionList: IProfession[] = [];
  public course: IResult[] = [];
  public additionalText: Array<string> = [];
  public additionalTextName: string = null;
  public additionalTextShortDescription: string = null;
  public results: any;
  public chartSize: number = 560; // [px]

  private refferalSessionId: string = null;
  private programsAmount: number = 1;
  private _sessionId: string = null;
  private ngUnsubscribe = new Subject<any>();

  public showAfterTestingText: boolean = false;
  public showResultsHeader: boolean = false;

  public regionId: string = '';
  public municipalityId: string = '';
  public selectLanguage: boolean = false;

  public parent: any = {};
  public resultsFetched: boolean = false;
  public parentResults: IResultsData;
  public pupilResults: IResultsData;
  public currentResults: IResultsData;
  public sharedResults: any;

  public testType: string;
  testTypesEnum = ETestTypes;

  public isSchoolStaff: boolean = false;
  public isMinCifry: boolean = false;

  public readonly openResultsText: IInterestsAndSkillsText = {
    title: 'Интересы и предпочтения',
    description:
      'Это те направления, где с большой вероятностью ваш ребенок готов развиваться без дополнительных стимулов. Чем выше направление в рейтинге — тем больше оно может интересовать ребенка. Правильная работа с интересами может помочь достичь высоких результатов в школьной программе и профессиональной деятельности в будущем.',
  };
  protected readonly SpiderChartThemeEnum = SpiderChartThemeEnum;
  protected readonly SpiderChartFiguresEnum = SpiderChartFiguresEnum;

  @Output() resultsEmitter = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resultService: ResultsService,
    private location: Location,
    private overlayService: OverlayBusyService,
    private translate: TranslateService,
    private apiResultsService: ApiResultsService,
    private apiCatalogService: ApiSearchService,
    private apiProfilesService: ApiProfilesService,
    private apiPlayerService: ApiPlayerService,
    private webStorageService: WebStorageService,
    private b2gSaasService: B2gSaasService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguage =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == this.webStorageService.get(StorageKeys.RegionId);
    this.programsAmount = 3;
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.testType = this.webStorageService.get(StorageKeys.TestType);
    this.openResults = this.router.url.includes('results-open');
    this.isMinCifry = this.testType === this.testTypesEnum.MINCIFRYDEMO_TEST.toString();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setChartSize(event.target.innerWidth);
  }

  ngOnInit() {
    if (this.showOnlyParentResults) {
      this.showParent = true;
      this.showPupil = false;
    }

    this.overlayService.show();
    this.setChartSize(window.innerWidth);
    this.getLocalizationData();
    this.checkUserRole();

    if (this.userInfo.role) {
      this.getSessionData();
    }
  }
  public getSessionData() {
    // Отображение теста по сессии из URL
    this.route.params
      .pipe(
        switchMap(params => {
          if (params['ssid']) {
            this._sessionId = params['ssid'];
            this.webStorageService.set(StorageKeys.TestSessionId, this._sessionId);
            this.webStorageService.set(StorageKeys.SessionStatus, 'Success');

            const sessionIds = [];
            sessionIds.push(this._sessionId);
            const getResultsObject = {
              regionId: this.userInfo.regionId,
              municipalityId: this.municipalityId,
              sessionIds: sessionIds,
              role: this.userInfo.role,
            };

            if (this.selectLanguage) {
              return this.apiPlayerService.getSession(this._sessionId).pipe(
                switchMap(session => {
                  Object.assign(getResultsObject, { language: session.language });
                  return this.b2gSaasService.getResultPage(getResultsObject).pipe(
                    tap((results: IResultsData) => {
                      if (results) {
                        this.results = results;
                        this.parseResults(this.results);
                      }
                    }),
                  );
                }),
              );
            } else {
              return this.b2gSaasService.getResultPage(getResultsObject).pipe(
                tap(results => {
                  if (results) {
                    this.results = results;
                    this.parseResults(this.results);
                  }
                }),
              );
            }
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(() => {
        if (!this._sessionId) {
          this.showBySharedResults();
        }
      });
  }

  public showBySharedResults() {
    let pupilUserId = '';
    let parentUserId = '';

    //в sharedResults заносятся только сессии теста на таланты родителя и детей, как завершенные, так и не завершенные
    let sharedResultsObservable$: Observable<any> = of(null);

    if (this.userInfo.role === 'parent') {
      pupilUserId = this.child.userId;
      parentUserId = this.userInfo.userId;
      sharedResultsObservable$ = this.apiProfilesService.getSharedResults(parentUserId).pipe(
        switchMap(sharedResults => {
          return this.getRegionTestInfo().pipe(
            map(testInfo => {
              return sharedResults.filter(ru => ru.refferalUserId === pupilUserId && ru.screeningTestId === testInfo.screeningTestId);
            }),
          );
        }),
      );
    } else if (this.userInfo.role === 'pupil') {
      pupilUserId = this.userInfo.userId;
      sharedResultsObservable$ = this.apiProfilesService.getSharedResults(pupilUserId);
    } else if (
      this.userInfo.role === 'teacher' ||
      this.userInfo.role === 'schooladmin' ||
      this.userInfo.role === 'director' ||
      this.userInfo.role === 'tutor' ||
      this.userInfo.role === 'admin'
    ) {
      pupilUserId = this.child.userId;
      sharedResultsObservable$ = this.apiProfilesService.getSharedResults(pupilUserId);
    }

    // -----------------------------------------------------------------

    sharedResultsObservable$
      .pipe(
        switchMap(sharedResults => {
          //  let resultPageObservable$: Observable<any>;
          let sharedResultPageObservable$: Observable<any> = of(null);
          let parentResultPageObservable$: Observable<any> = of(null);
          let childResultPageObservable$: Observable<any> = of(null);

          if (sharedResults && sharedResults.length) {
            //ненулевая сессия - значит, тест начат
            this.pupilSessionId = sharedResults[0].refferalSessionId !== EmptyGuid ? sharedResults[0].refferalSessionId : null;
            // первая сесси в списке должна быть последней начатой сессией
            this.parentSessionId = sharedResults[0].sessionId;

            if (
              this.userInfo.role === 'pupil' ||
              this.userInfo.role === 'teacher' ||
              this.userInfo.role === 'schooladmin' ||
              this.userInfo.role === 'director' ||
              this.userInfo.role === 'tutor'
            ) {
              parentUserId = sharedResults[0].userId;
            }

            const sessionIds = this.pupilSessionId ? [this.parentSessionId, this.pupilSessionId] : [this.parentSessionId];

            const getResultsObject = {
              regionId: this.userInfo.regionId,
              municipalityId: this.userInfo.municipalityId,
              sessionIds: sessionIds,
              role: this.userInfo.role,
            };

            const getResultsParentObject = {
              regionId: this.userInfo.regionId,
              municipalityId: this.userInfo.municipalityId,
              sessionIds: this.parentSessionId ? [this.parentSessionId] : [],
              role: this.userInfo.role,
            };

            const getResultsPupilObject = {
              regionId: this.userInfo.regionId,
              municipalityId: this.userInfo.municipalityId,
              sessionIds: this.pupilSessionId ? [this.pupilSessionId] : [],
              role: this.userInfo.role,
            };

            if (this.selectLanguage) {
              // берем сессию родителя
              sharedResultPageObservable$ = this.apiPlayerService.createSession(this.parentSessionId).pipe(
                mergeMap(session => {
                  Object.assign(getResultsObject, { language: session.language });
                  return this.b2gSaasService.getResultPage(getResultsObject);
                }),
              );
            } else {
              parentResultPageObservable$ = this.b2gSaasService.getResultPage(getResultsParentObject);
              childResultPageObservable$ = this.b2gSaasService.getResultPage(getResultsPupilObject);
              sharedResultPageObservable$ = this.b2gSaasService.getResultPage(getResultsObject);
            }
          } else {
            //пробуем загрузить сессию ребенка, если нет sharedResults
            sharedResultPageObservable$ = this.b2gSaasService.getLastSession(pupilUserId).pipe(
              switchMap(response => {
                const lastSessionId = response.sessionId;

                if (response && response.status === 'Success' && lastSessionId != EmptyGuid) {
                  return this.apiPlayerService.getSession(lastSessionId).pipe(
                    mergeMap(lastSession => {
                      let userResultsObservable$: Observable<any> = of(null);

                      this.pupilSessionId = lastSession.sessionId !== EmptyGuid ? lastSession.sessionId : '';

                      if (lastSession && lastSession.completed) {
                        const sessionIds = [];
                        sessionIds.push(lastSession.sessionId);
                        const getResultsObject = {
                          regionId: this.userInfo.regionId === 'null' ? null : this.userInfo.regionId,
                          municipalityId: this.userInfo.municipalityId === 'null' ? null : this.userInfo.municipalityId,
                          sessionIds: sessionIds,
                          role: this.userInfo.role,
                        };

                        if (this.selectLanguage) {
                          userResultsObservable$ = this.apiPlayerService.createSession(lastSession.sessionId).pipe(
                            mergeMap(session => {
                              Object.assign(getResultsObject, { language: session.language });
                              return this.b2gSaasService.getResultPage(getResultsObject);
                            }),
                          );
                        } else {
                          userResultsObservable$ = this.b2gSaasService.getResultPage(getResultsObject);
                        }
                      }

                      return userResultsObservable$;
                    }),
                  );
                } else {
                  return of(null);
                }
              }),
            );
          }

          return forkJoin([parentResultPageObservable$, childResultPageObservable$, sharedResultPageObservable$]).pipe(
            switchMap(([parentResults, pupilResults, results]) => {
              if (!results || !results.results || results.results.length === 0) {
                this.nullResults = true;
                setTimeout(() => this.overlayService.hide(), 500);
                // данные загружены
                this.resultsFetched = true;
                this.loaded.emit(true);
                return of(null);
              }

              this.parentResults = parentResults;
              this.pupilResults = pupilResults;
              this.sharedResults = results;

              const sessionsRequestArray$: Observable<any>[] = [];
              [this.pupilSessionId, this.parentSessionId].forEach(el => {
                if (el) {
                  sessionsRequestArray$.push(this.apiPlayerService.getSession(el));
                }
              });
              return forkJoin(sessionsRequestArray$).pipe(
                tap((sessions: any[]) => {
                  const pupilSession = sessions.find(session => session.userId === pupilUserId);

                  if (pupilSession) {
                    this.isCompleteTestPupil = pupilSession.completed;
                    this.isStartTestPupil = true;
                  } else {
                    this.isCompleteTestPupil = false;
                  }

                  const parentSession = sessions.find(session => session.userId === parentUserId);
                  if (pupilSession) {
                    this.isCompleteTestPupil = !!pupilSession.completed;
                  }
                  if (parentSession) {
                    this.isCompleteTestParent = !!parentSession.completed;
                  }
                  //нужно для передачи в test-switch
                  if (this.child) {
                    this.child = pupilSession
                      ? Object.assign(this.child, { lastSession: pupilSession })
                      : Object.assign(this.child, { lastSession: undefined });
                  } else {
                    this.child = null;
                  }
                  this.parent = parentSession ? Object.assign(this.parent, { lastSession: parentSession }) : null;

                  this.showPupil = this.isCompleteTestPupil;
                  this.showParent = this.isCompleteTestParent;

                  this.resultService.isCompleteTestPupil = this.isCompleteTestPupil;
                  this.pupilSessionComplete.emit(this.isCompleteTestPupil);
                  this.parentSessionComplete.emit(this.isCompleteTestParent);
                  this.pupilSessionStart.emit(this.isStartTestPupil);

                  if (this.isCompleteTestPupil && !this.isCompleteTestParent) {
                    this._sessionId = this.pupilSessionId;
                  }

                  if (!this.isCompleteTestPupil && this.isCompleteTestParent) {
                    this._sessionId = this.parentSessionId;
                  }

                  if (this.isCompleteTestPupil && this.isCompleteTestParent) {
                    this._sessionId = this.parentSessionId;
                  }

                  if (results && (this.isCompleteTestPupil || this.isCompleteTestParent)) {
                    this.parseResults(results);
                  } else {
                    this.overlayService.hide();
                    this.loaded.emit(true);
                  }
                }),
              );
            }),
          );
        }),
      )
      .subscribe();
  }

  getRegionTestInfo(): Observable<ITestInfo> {
    return this.apiPlayerService.regionTestInfo(this.userInfo.regionId).pipe(map((testInfo: ITestInfo) => testInfo));
  }

  checkResults() {
    setTimeout(_ => {
      if (!this.showParent && this.showPupil) {
        this._sessionId = this.pupilSessionId;
        this.parseResults(this.pupilResults);
      }

      if (this.showParent && !this.showPupil) {
        this._sessionId = this.parentSessionId;
        this.parseResults(this.parentResults);
      }

      if (this.showParent && this.showPupil) {
        this._sessionId = this.parentSessionId;
        this.parseResults(this.sharedResults);
      }
    }, 50);
  }

  private checkUserRole() {
    if (this.userInfo.role === 'pupil') {
      return (this.showAfterTestingText = true);
    }

    this.isSchoolStaff = this.userInfo.role === 'director' || this.userInfo.role === 'schooladmin' || this.userInfo.role === 'teacher';

    return (this.showAfterTestingText = false);
  }

  private setChartSize(size: number) {
    if (size > 890 && size <= 960) {
      this.chartSize = 500;
    } else if (size > 460 && size <= 890) {
      this.chartSize = 450;
    } else if (size <= 460) {
      this.chartSize = 400;
    } else {
      this.chartSize = 560;
    }
  }

  private prepareAdditionalText(text: string): Array<string> {
    const result: Array<string> = [];
    if (text && text.length) {
      const textList = text.replace(/[ ]?<p>[ ]?/gi, '').split('</p>');

      textList.forEach((t, i) => {
        if (i < 4) {
          result.push(t);
        }
      });
    }
    return result;
  }

  private getLocalizationData(): void {
    this.translate
      .getTranslation(this.translate.currentLang)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.localization = response;
      });
  }

  private parseResults(results: IResultsData) {
    let resultsData;
    if (!results || !results.results || results.results.length === 0) {
      this.nullResults = true;
      setTimeout(() => this.overlayService.hide(), 500);
      // данные загружены
      this.dataLoaded = true;
      this.loaded.emit(true);
      return;
    }

    resultsData = JSON.parse(JSON.stringify(results));

    if ((!this.showParent && this.showPupil) || (this.showParent && !this.showPupil)) {
      resultsData.results = resultsData.results.map(item => {
        item.results = item.results.filter(s => s.sessionId === this._sessionId);
        return item;
      });
    }
    if (resultsData.results[0].results.length === 0) {
      this.nullResults = true;
      setTimeout(() => this.overlayService.hide(), 500);
      // данные загружены
      this.dataLoaded = true;
      this.loaded.emit(true);
      return;
    }

    this.getAdditionalText();

    this.currentResults = resultsData;

    this.skillsAttainment = resultsData.results.filter(d => d.objectType === 'SkillAttainment');

    this.interests = resultsData.results.filter(d => d.objectType === 'TalentAttainment');
    this.talents = resultsData.results.filter(d => d.objectType === 'Talent');
    const fields = resultsData.results.filter(d => d.objectType === 'Field');
    this.fieldsData = fields.sort((a, b) => {
      if (a.results[0] && b.results[0]) {
        return a.results[0]['transformedValue'] > b.results[0]['transformedValue'] ? -1 : 1;
      } else {
        return -1;
      }
    });
    this.selectedField = this.fieldsData[0];

    this.activeProfessionList = this.professionList.filter(p => {
      return this.FilterFields(p, this.selectedField.name);
    });
    this.interestsData = this.resultService.transformInterests(this.interests);
    this.skillsAttainmentData = this.resultService.transformSkills(this.skillsAttainment, this._sessionId);

    // получаем урл картинок для талантов
    this.apiCatalogService
      .searchTalents()
      .pipe(
        take(1),
        tap(data => {
          this.talentsData = this.talents
            .map(t => {
              let description: string = null;
              const talentDataElement = data.find(talent => talent.id === t.id);
              switch (this.userInfo.role) {
                case 'pupil':
                  description = talentDataElement?.descriptionForPupil;
                  break;
                case 'parent':
                  description = talentDataElement?.descriptionForParent;
                  break;
                default:
                  description = talentDataElement?.descriptionForTutor;
              }

              if (!description) {
                description = t.description;
              }

              return {
                name: t.name,
                description: description,
                shortDescription:
                  description.length < shortDescriptionLen ? description : description.slice(0, shortDescriptionLen - 2) + '..',
                value: t.results.length ? t.results[0].transformedValue : 0,
                imageUrl: document.location.origin + this.location.prepareExternalUrl(t.imageUrl || talentDataElement.icon),
                title: this.isEn() ? t.name : t.data.DativeCase,
              };
            })
            .sort((a, b) => b.value - a.value)
            .slice(0, this.resultService.TALENTS_TO_CHOOSE);

          setTimeout(() => this.overlayService.hide(), 500);
          // данные загружены
          this.dataLoaded = true;
          this.loaded.emit(true);
        }),
      )
      .subscribe();
  }

  private FilterFields(dataFields, fieldName) {
    for (const i in dataFields.fieldNames) {
      if (dataFields.fieldNames[i] === fieldName) {
        return true;
      }
    }
    return false;
  }

  public routeHome(userRole) {
    switch (userRole) {
      case 'schooladmin':
        return `/${userRole}/classes`;
      default:
        return `/${userRole}`;
    }
  }

  private isEn = () => this.translate.currentLang === 'en';

  private getAdditionalText(): void {
    if (this.results && this.results.additionalText) {
      this.additionalTextName = this.results.additionalText.name;
      this.additionalTextShortDescription = this.results.additionalText.shortDescription;
      this.additionalText = this.prepareAdditionalText(this.results.additionalText.text);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
