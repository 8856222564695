import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ApiInternshipsService, IFilterInternships } from '@profilum-library';
import { InternshipsFiltersService } from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'prf-active-internships',
  templateUrl: './active-internships.component.html',
  styleUrls: ['./active-internships.component.scss'],
})
export class ActiveInternshipsComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  public searches: any = [];
  public internships: any = [];
  public internshipsCount: string;
  public internshipsSearchCount: string;
  public isSearched: boolean = false;
  public viewValue: boolean = false;
  filters: IFilterInternships;

  constructor(
    private meta: Meta,
    private filtersService: InternshipsFiltersService,
    private apiInternshipsService: ApiInternshipsService,
  ) {}

  ngOnInit() {
    this.findInternshipsCountByRegion();
  }

  // значение из поиска
  dataSearch(data) {
    this.searches = data;
    this.isSearched = false;
    if (this.searches.length > 0) {
      return this.countSearchInternships(this.searches.length);
    }
  }

  findInternshipsCountByRegion() {
    let filters = Object.assign({}, this.filters);
    this.filtersService.setRegionMunicipalityFilters(filters);
    this.subscription = this.apiInternshipsService.getInternshipsCount(filters).subscribe(count => {
      return this.countInternships(null, count);
    });
  }

  // счетчик из фильтров и при ngOnInit
  public countInternships(coursesLength: number, searchLength: number = 0) {
    let complexLength = coursesLength + searchLength;
    let courseTitles = ['стажировка', 'стажировки', 'стажировок'];
    let title = this.filtersService.getTitleByNumber(complexLength, courseTitles);
    return (this.internshipsCount = complexLength + ' ' + title);
  }

  // счетчик из search
  public countSearchInternships(searchLength: number) {
    this.isSearched = true;
    let complexLength = searchLength;
    let courseTitles = ['стажировка', 'стажировки', 'стажировок'];
    let title = this.filtersService.getTitleByNumber(complexLength, courseTitles);
    return (this.internshipsSearchCount = complexLength + ' ' + title);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
