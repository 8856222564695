import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { Observable, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AddFirstProfessionModalService } from '../add-first-profession-modal/add-first-profession-modal.service';
import { UserRoles } from 'app/shared/enums/userroles.enum';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import {
  ApiFavoritesService,
  ApiProfessionsService,
  AppSettingsService,
  B2gSaasService,
  EmptyGuid,
  FavoritesDataHandlerService,
  IGetUserRecommendationsRequest, IProfession,
  IUserInfo,
  IUserProfileInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { RootScopeService } from '../../../../services/root-scope.service';
import { DictionaryType } from '../../../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-recommended-professions',
  templateUrl: './recommended-professions.component.html',
  styleUrls: ['./recommended-professions.component.scss'],
})
export class RecommendedProfessionsComponent extends UnsubscribeComponent implements OnDestroy {
  @Input() childId: string = '';

  public userInfo: IUserInfo;
  public userRoles = UserRoles;
  public sessionStatus: string = '';
  public professionsByResults: Array<any> = [];
  public professionsByParent: Array<any> = [];
  public professionsByTeacher: Array<any> = [];
  public professionsAll: Array<any> = [];
  public dictionary: DictionaryType;

  recommendedByTest: string = '';
  public recommendMenu = [
    {
      name: '',
      selected: true,
      active: false,
      showPopup: false,
    },
    {
      name: 'От родителей',
      selected: false,
      active: false,
      showPopup: false,
    },
    {
      name: 'От учителя',
      selected: false,
      active: false,
      showPopup: false,
    },
  ];

  private disableShowProfession: boolean = false;
  private favoritesList: Array<any> = [];
  private _testResults: any;
  private _pupilSessionId: string;
  public showSalary: boolean = false;
  @Input() set testResults(val: any) {
    this._testResults = val;
    this.loadData();
  }

  @Input() set pupilSessionId(val: any) {
    if (val) {
      this._pupilSessionId = val;
    }
  }

  constructor(
    private router: Router,
    private apiProfessionsService: ApiProfessionsService,
    private goalsService: GoalsService,
    private addFirstProfessionModalService: AddFirstProfessionModalService,
    private translateService: TranslateService,
    private apiFavoritesService: ApiFavoritesService,
    private rootScopeService: RootScopeService,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
    private saasService: B2gSaasService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
    this.showSalary = AppSettingsService.settings.professionViewConfiguration.showSalary;

    this.getTranslation('SHARED.RESULT.BY_TEST')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(translation => {
        this.recommendedByTest = translation;
        this.recommendMenu[0].name = translation;
      });

    this.recommendMenu[2].name = `От ${this.dictionary.TeacherGenitive}`;
  }

  public loadData() {
    this.favoritesList = this.favoritesDataHandlerService.getFavoriteProfessions().getValue();
    this.getProfessionsAll()
      .pipe(switchMap(() => this.getRecommended()))
      .subscribe(() => this.getByResult());
  }

  get currentRecommendMenuItem(): any {
    const menuItem: Array<any> = this.recommendMenu.filter(rm => rm.selected);

    return menuItem && menuItem[0] ? menuItem[0] : this.recommendMenu[0];
  }

  public recommendMenuSelect(menuItem) {
    if (menuItem.active) {
      this.recommendMenu = this.recommendMenu.map(rm => {
        rm.selected = false;
        return rm;
      });
      menuItem.selected = true;
    }
  }

  public navigateProfessions(profession) {
    if (!this.disableShowProfession) {
      this.router.navigate(['/professions', profession.hrid]);
    }

    this.disableShowProfession = false;
  }

  public addToFavorite(profession: any) {
    this.disableShowProfession = true;

    this.apiFavoritesService
      .addToFavorites(profession.id, 'Profession', this.userInfo.userId)
      .pipe(take(1))
      .subscribe(response => {
        if (this.favoritesList.length === 0) {
          this.addFirstProfessionModalService.open('/pupil/' + profession.id);
        } else {
          this.router.navigate(['/pupil/' + profession.id]);
        }
        this.favoritesDataHandlerService.addToFavorites({ productId: profession.id, id: response.id });
        profession.addedToFavorite = true;
      });
  }

  public removeFavorite(profession: any) {
    this.disableShowProfession = true;

    const favoriteIds = this.favoritesList.filter(fl => fl.productId === profession.id).map(fl => fl.id);

    if (!favoriteIds[0]) {
      return;
    }

    this.apiFavoritesService
      .deleteFromFavorites(favoriteIds[0])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.favoritesList = this.favoritesList.filter(listItem => listItem.id !== favoriteIds[0]);
        this.favoritesDataHandlerService.deleteFavoriteProfession(favoriteIds[0]);
        profession.isGoal = false;
      });
  }

  public removeTags(test: string): string {
    if (test && test.length) {
      return test.replace(/<p>/gi, '').replace(/<\/p>/gi, '');
    }

    return '';
  }

  private getByResult() {
    if (this._testResults && this._testResults.length > 0) {
      // берем результаты ребенка для рекомендаций профессий
      const childResultsIndex = this._testResults[0].results.indexOf(
        this._testResults[0].results.find(el => el.sessionId === this._pupilSessionId),
      );

      // проверка, что ребенок прошел тест
      if (childResultsIndex > -1) {
        const fieldsList = this._testResults
          .filter(d => d.objectType === 'Field')
          .sort((a, b) => {
            if (a.results[childResultsIndex] && b.results[childResultsIndex]) {
              return a.results[childResultsIndex]['transformedValue'] > b.results[childResultsIndex]['transformedValue'] ? -1 : 1;
            }
          });
        const professions = this._testResults
          .filter(d => d.objectType === 'Profession' && d.results.length)
          .sort((a, b) => {
            if (a.results[childResultsIndex] && b.results[childResultsIndex]) {
              return a.results[childResultsIndex]['transformedValue'] > b.results[childResultsIndex]['transformedValue'] ? -1 : 1;
            }
          });

        professions.forEach(profession => {
          profession.data.fieldNames = this.professionsAll.find((el: IProfession) => el.id === profession.id)?.fields[0].name;
        })

        const professionsListByFields: Array<any> = [];

        for (let i = 0; i < 3; i++) {
          professionsListByFields.push({
            field: fieldsList[i],
            professions: professions.filter(p => p.data.fieldNames === fieldsList[i].name),
          });
        }

        const professionsListByFieldsDetail: Array<any> = [];

        professionsListByFields.forEach(plf => {
          const professions = plf.professions.map(p => {
            const profession = this.professionsAll.filter(pa => pa.id === p.id);
            return profession && profession.length ? profession[0] : p;
          });

          professionsListByFieldsDetail.push({
            field: plf.field,
            professions: professions,
          });
        });
        let professionsListByFieldsDetailEducation: Array<any> = [];

        professionsListByFieldsDetail.forEach(plfd => {
          const higherEducation = plfd.professions.filter(p => p.education && p.education.some(e => e.level === 3 && e.isPreferred));
          const secondaryEducation = plfd.professions.filter(p => p.education && p.education.some(e => e.level === 2 && e.isPreferred));

          professionsListByFieldsDetailEducation.push({
            tab: '',
            field: plfd.field,
            professions: {
              secondaryEducation: secondaryEducation,
              higherEducation: higherEducation,
            },
          });
        });

        professionsListByFieldsDetailEducation = professionsListByFieldsDetailEducation.map(row => {
          row.tab = row.professions.secondaryEducation.length ? 'secondaryEducation' : 'higherEducation';
          return row;
        });

        this.professionsByResults = professionsListByFieldsDetailEducation;
        this.checkMenu();
      }
    }
  }

  private getProfessionsAll(): Observable<any> {
    const favoritesProfessionsIds = this.favoritesList.map(fl => fl.productId);
    const userData = this.userDataHandlerService.getUserInfo();
    return this.apiProfessionsService
      .getElasticProfessionsByFilters({
        regionId: userData.regionId || EmptyGuid,
        municipalityId: userData.municipalityId,
        isVisible: true,
      })
      .pipe(
        tap(r => {
          this.professionsAll = r;

          this.professionsAll = this.professionsAll.map(p => {
            p.mainImagePath = p.mainImagePath ? './profilum-assets/images/profession/' + p.mainImagePath.substring(42) : null;
            p.isGoal = favoritesProfessionsIds.indexOf(p.id) > -1;
            return p;
          });
        }),
      );
  }

  private getRecommended(): Observable<any> {
    return forkJoin([
      this.getRecommendedProfessions('Profession', 'Parent', this.childId),
      this.getRecommendedProfessions('Profession', 'Teacher', this.childId),
    ]).pipe(
      tap(([parentProfessions, teacherProfessions]) => {
        this.professionsByParent = parentProfessions;
        this.professionsByTeacher = teacherProfessions;
        this.checkMenu();
      }),
    );
  }

  private getRecommendedProfessions(recommendationType: string, role: string, userId: string): Observable<any> {
    let recommendations: Observable<any>;
    const params: IGetUserRecommendationsRequest = {
      recommendationType,
      addresserRole: role,
    };
    const route = this.userInfo.role === 'admin' ? 'saas' : this.userInfo.role + 's';

    if (this.userInfo.role === 'pupil') {
      recommendations = this.saasService.getUserRecommendations(params);
    } else {
      params.userId = userId;
      recommendations = this.saasService.getUserRecommendations(params, route);
    }

    return recommendations.pipe(
      switchMap(recommendations => {
        if (recommendations?.length) {
          return this.apiProfessionsService.getElasticProfessionsByFilters({ ids: recommendations }).pipe(
            tap(professions => {
              professions.forEach(el => {
                el.mainImagePath = el.mainImagePath ? './profilum-assets/images/profession/' + el.mainImagePath.substring(42) : null;
              });
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  private checkMenu() {
    this.recommendMenu = this.recommendMenu.map(rm => {
      switch (rm.name) {
        case this.recommendedByTest:
          rm.active = this.professionsByResults?.length > 0;
          break;
        case 'От родителей':
          rm.active = this.professionsByParent?.length > 0;
          break;
        case 'От учителя':
          rm.active = this.professionsByTeacher?.length > 0;
          break;
      }

      return rm;
    });
  }

  checkProfession(profession: any) {
    const regionId: string = this.webStorageService.get(StorageKeys.RegionId);
    const municipalityId: string = this.webStorageService.get(StorageKeys.MunicipalityId);
    return profession.regionId === regionId && profession.municipalityId === municipalityId;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
