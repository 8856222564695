import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyPupilsInvitationComponent } from 'app/shared/common-components/copy-pupils-invitation/copy-pupils-invitation.component';

@NgModule({
  declarations: [CopyPupilsInvitationComponent],
  imports: [CommonModule],
  exports: [CopyPupilsInvitationComponent],
})
export class CopyPupilsInvitationModule {}
