import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiAdminsService,
  ApiFavoritesService,
  ApiSchoolsService,
  ApiUsersService,
  B2gSaasService,
  CalculationService,
  EmptyGuid,
  FavoritesDataHandlerService,
  IFilterClasses,
  IUserInfo,
  ProductTypes,
  Stands,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { DateHelper } from '@profilum-helpers/date-helper/date-helper';

import { AGE_DATA } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filter.component';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ServerErrorMessage } from 'app/shared/global-constants/constants';
import { ClassesFormatTypes } from '../../../../shared/enums/courses-types.enum';
import { TeacherPanelService } from '../../../control-panel/teacher/teacher-panel.service';
import { COMPLEXITYS, COURSES_TYPES } from '../../courses-page/all-courses/active-courses/add-course/add-course.component';
import { MetroColors } from '../../courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';

@Component({
  selector: 'prf-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  course: any;
  types: any = COURSES_TYPES;
  classesFormat: string;
  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherCourses: any = [];
  similarCourses: any = [];
  favoritesCourses: any;
  courseEnrolls: any;
  userData: IUserInfo;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  isCourse: boolean;
  startDate: string;
  complexity: string = '';

  isEnrolled: boolean = false;
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  metroColors: MetroColors = new MetroColors();

  fromString = '';
  beforeString = '';
  yearsString = '';
  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private b2gSaasService: B2gSaasService,
    private calculationService: CalculationService,
    private teacherService: TeacherPanelService,
    private apiSchoolsService: ApiSchoolsService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private apiFavoritesService: ApiFavoritesService,
    private apiUsersService: ApiUsersService,
    private translateService: TranslateService,
    private apiAdminsService: ApiAdminsService,
    private webStorageService: WebStorageService,
    private router: Router,
    private userDataHandlerService: UserDataHandlerService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    this.getTranslations(['SHARED.COURSE', 'SHARED.FROM', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.meta.updateTag({ name: 'og:title', content: translations['SHARED.COURSE'] });
        this.fromString = this.beforeString;
        this.yearsString;
      });
    if (this.userRole.includes('admin')) {
      this.adminLevel = this.webStorageService.get(StorageKeys.AdminLevel);
    }
    this.userData = this.userDataHandlerService.getUserInfo();
    this.userRole = this.userData.role;
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();

    // here
    this.route.params
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentCourse();
        }),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCurrentCourse(): Observable<any> {
    return this.apiSchoolsService.getCourseByHrid(this.hrid).pipe(
      switchMap((data: any) => {
        if (data.status == 'Not Found') {
          this.utilsService.openSnackBar(`Не удалось найти курс с hrid = ${this.hrid}`, 'error');
          this.router.navigate([this.backButtonUrl()]);
        }
        this.course = data.class;
        const type = this.types.find(el => el.value === this.course.classesFormat);
        this.classesFormat = type.viewValue;
        const complexity = COMPLEXITYS.find(c => c.value === this.course.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.color = type.color.length > 0 ? type.color : '';
        const startTime = DateHelper.toDayJs(this.course.startDate).format('HH:mm');
        if (startTime == '00:00') {
          this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY');
        } else {
          this.startDate = DateHelper.toDayJs(this.course.startDate).format('D MMMM YYYY в HH:mm');
        }

        let classEnrollObservable$: Observable<any>;

        if (this.userRole === 'parent') {
          this.loadFavorites().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
        }

        if (this.userRole === 'pupil') {
          classEnrollObservable$ = this.loadFavorites().pipe(
            switchMap(() =>
              this.apiUsersService.getClassEnrollPupils(this.userData.userId, this.course.id).pipe(
                tap(classEnroll => {
                  this.isEnrolled = classEnroll && classEnroll.isDeleted === false;
                }),
              ),
            ),
          );
        } else {
          classEnrollObservable$ = of(null);
        }
        return classEnrollObservable$;
      }),
    );
  }

  loadOtherCourses(): Observable<any> {
    if (this.course.institutionId && this.course.institutionId !== EmptyGuid) {
      const filter: IFilterClasses = {
        classesFormat: [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse],
        stand: Stands.Talent,
        from: 0,
        size: 4,
        municipalityId: this.userData.municipalityId,
      };
      if (this.isCourse) {
        filter.classesFormat.push(
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        );
      }
      const firstFilter = { ...filter };
      const secondFilter = { ...filter };
      firstFilter.institutionId = this.course.institutionId;
      secondFilter.courses = this.course.courses;
      secondFilter.size = 13;
      return observableForkJoin([
        this.apiSchoolsService.getElasticFilteredClasses(firstFilter),
        this.apiSchoolsService.getElasticFilteredClasses(secondFilter),
      ]).pipe(
        tap(([otherCourses, similarCourses]) => {
          this.otherCourses = otherCourses.filter(d => d.id !== this.course.id);
          this.similarCourses = similarCourses.filter(d => d.id !== this.course.id);
        }),
      );
    } else {
      return of(null);
    }
  }

  loadFavorites(): Observable<any> {
    return of(() => {
      this.favoritesCourses = this.favoritesDataHandlerService.getFavoriteClasses().getValue();

      if (this.favoritesCourses && this.course.id) {
        const productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);

        this.isFavorite = productIds.length > 0;
      }
    });
  }

  archive(course) {
    course.isArchived = true;
    const observables: Observable<any>[] = [];
    // mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.apiAdminsService.replaceClassDO(course));
    } else if (this.userRole == 'adminDO') {
      observables.push(this.apiAdminsService.replaceAdminDOClass(course));
    } else if (this.userRole == 'director') {
      observables.push(this.apiUsersService.replaceClassDODirectors(course));
    } else {
      // admin
      observables.push(this.apiSchoolsService.changeClassesDO(course));
    }
    observableForkJoin(observables)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(() =>
          this.getTranslations(['SHARED.COURSE_ADDED_TO_ARCHIVE']).pipe(
            tap(translations => {
              this.utilsService.openSnackBar(translations['SHARED.COURSE_ADDED_TO_ARCHIVE'], 'success');
              this.addtoArchive = !this.addtoArchive;
            }),
          ),
        ),
      )
      .subscribe();
  }

  addToFavorite() {
    this.apiFavoritesService
      .addToFavorites(this.course.id, 'Class', this.userData.userId)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap(() => this.favoritesDataHandlerService.needsUpdate.next(ProductTypes.Class)),
        switchMap(() => this.loadFavorites()),
      )
      .subscribe();
  }

  removeFavorite() {
    const filteredFavorites = this.favoritesCourses.filter(el => el.productId === this.course.id);
    const courseFavoriteIds = filteredFavorites.map(el => el.id);
    if (courseFavoriteIds) {
      courseFavoriteIds.forEach(favoriteId =>
        this.apiFavoritesService.deleteFromFavorites(favoriteId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(),
      );
      this.isFavorite = false;
    }
  }

  goToCourse() {
    if (!this.isEnrolled) {
      let currentObservable$: Observable<any> = of(null);
      if (this.userRole === 'parent') {
        currentObservable$ = this.b2gSaasService.addClassEnroll(this.course.id);
      }
      if (this.userRole === 'pupil') {
        currentObservable$ = this.apiUsersService.addClassEnrollPupils(this.course.id);
      }
      currentObservable$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => (this.isEnrolled = true));
    }
  }

  cancelClassEnroll() {
    if (this.isEnrolled) {
      this.apiUsersService
        .removeClassEnrollPupils(this.course.id, this.userData.userId)
        .pipe(take(1))
        .subscribe(() => (this.isEnrolled = false));
    }
  }

  getAges() {
    if (this.course.maxAgeAudience != 100 && this.course.minAgeAudience != 0) {
      return this.fromString + ' ' + this.course.minAgeAudience + this.beforeString + this.course.maxAgeAudience + this.yearsString;
    } else if (this.course.maxAgeAudience == 100) {
      return this.course.minAgeAudience + '+ ' + this.yearsString;
    } else if (this.course.minAgeAudience == 0) {
      return this.fromString + ' ' + this.course.maxAgeAudience + ' ' + this.yearsString;
    }
  }

  calculateRealPrice() {
    return this.calculationService.calculateRealPrice(this.course);
  }

  setTitles() {
    const path = this.router.routerState.snapshot.url;
    if (path.includes('/events/')) {
      this.getTranslations(['SHARED.EVENTS', 'SHARED.EVENT_ON_MAP'])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(translations => {
          this.title = translations['SHARED.EVENTS'];
          this.mapHeader = translations['SHARED.EVENT_ON_MAP'];
          this.isCourse = false;
        });
    }
    if (path.includes('/courses/')) {
      this.getTranslations(['COURCES.CATALOG.COURCES', 'SHARED.EVENT_ON_MAP'])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(translations => {
          this.title = translations['COURCES.CATALOG.COURCES'];
          this.mapHeader = translations['SHARED.COURSE_ON_MAP'];
          this.isCourse = true;
        });
    }
  }

  backButtonUrl() {
    switch (this.userRole) {
      case 'admin':
        return '/admin/courses';
      case 'schooladmin':
        return '/schooladmin/courses';
      case 'adminDO':
        return '/adminDO/courses';
      case 'director':
        return '/director/courses';
      default:
        if (this.isCourse) {
          return '/courses';
        } else {
          return '/events';
        }
    }
  }

  deleteCourse(courseID) {
    const observables: Observable<any>[] = [];
    // mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.apiAdminsService.deleteClassDO(courseID));
    } else if (this.userRole == 'director') {
      observables.push(this.apiUsersService.deleteClassDODirectors(courseID));
    } else if (this.userRole == 'adminDO') {
      observables.push(this.apiAdminsService.removeAdminDOClass(courseID));
    } else {
      // admin
      observables.push(this.apiSchoolsService.removeCurrentClass(courseID));
    }
    // elastic
    observables.push(this.apiSchoolsService.removeCurrentClass(courseID));
    observableForkJoin(observables)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar(ServerErrorMessage, 'error');
          return throwError(err);
        }),
      )
      .subscribe(([delMongo, delElastic]) => {
        if (delMongo.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate([this.backButtonUrl()]);
      });
  }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
      case 'teacher':
        return true;
      default:
        return false;
    }
  }

  checkFavourite(): boolean {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  checkCourseEdit(course): boolean {
    let isEditable = false;
    isEditable =
      this.userRole == 'admin' ||
      (this.userRole == 'schooladmin' &&
        course.regionId === this.webStorageService.get(StorageKeys.RegionId) &&
        course.municipalityId === this.webStorageService.get(StorageKeys.MunicipalityId) &&
        course.institutionId === this.webStorageService.get(StorageKeys.SchoolId)) ||
      (this.userRole == 'director' &&
        course.regionId === this.webStorageService.get(StorageKeys.RegionId) &&
        course.municipalityId === this.webStorageService.get(StorageKeys.MunicipalityId) &&
        course.institutionId === this.webStorageService.get(StorageKeys.SchoolId));
    return isEditable;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
