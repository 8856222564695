<div class="pupil-mobile-edit-profile">
  <ng-container #content></ng-container>
</div>
<prf-pupil-mobile-edit-profile-menu
  class="profile-menu"
  *ngIf="!isHideProfileButtons"
  (saveProfileChanges)="submitChanges()"
  (cancelProfileChanges)="canselChanges()"
></prf-pupil-mobile-edit-profile-menu>

<ng-template #defaultTemplate>
  <div class="pupil-mobile-edit-profile-header">
    <div (click)="uploadPhoto.click()" class="pupil-mobile-edit-profile-header-img">
      <img *ngIf="userInfo?.imagePath; else initialsTemplate" [ngSrc]="userInfo?.imagePath" fill alt="Иконка ученика" />
      <ng-template #initialsTemplate>
        <div class="pupil-mobile-edit-profile-header-img pupil-mobile-edit-profile-header-img-initials">{{ initials }}</div>
      </ng-template>
      <div class="pupil-mobile-edit-profile-header-img-edit">
        <form [formGroup]="avatarForm">
          <i class="pupil-mobile-edit-profile-header-img-edit-icon"></i>
          <input type="file" accept="image/png, image/gif, image/jpeg" (change)="openImageEditor($event)" hidden #uploadPhoto />
        </form>
      </div>
    </div>
    <i (click)="close()" class="pupil-mobile-edit-profile-header-close-btn-icon"></i>
  </div>
  <div class="pupil-mobile-edit-profile-body">
    <form
      class="pupil-mobile-edit-profile-body-form"
      id="wf-form-User-Data"
      name="wf-form-User-Data"
      data-name="User Data"
      [formGroup]="form"
      autocomplete="off"
    >
      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="lastName" *ngIf="formControls.lastName.value">{{ 'SHARED.FORM.FAMILY' | translate }}</label>
        <input
          type="text"
          [class.error-field]="submitted && formControls.lastName.errors"
          name="lastName"
          data-name="lastName"
          id="lastName"
          formControlName="lastName"
          [placeholder]="'SHARED.FORM.FAMILY' | translate"
          autocomplete="off"
        />
      </div>

      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="firstName" *ngIf="formControls.firstName.value">{{ 'SHARED.FORM.NAME' | translate }}</label>
        <input
          type="text"
          [class.error-field]="submitted && formControls.firstName.errors"
          name="firstName"
          data-name="firstName"
          id="firstName"
          formControlName="firstName"
          [placeholder]="'SHARED.FORM.NAME' | translate"
          autocomplete="off"
        />
      </div>

      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="middleName" *ngIf="formControls.middleName.value">{{
          'SHARED.FORM.MIDDLENAME' | translate
        }}</label>
        <input
          type="text"
          autocomplete="off"
          [class.error-field]="submitted && formControls.middleName.errors"
          name="middleName"
          data-name="middleName"
          [placeholder]="'SHARED.FORM.MIDDLENAME' | translate"
          id="middleName"
          formControlName="middleName"
        />
        <span class="pupil-mobile-edit-profile-body-form-control-not-necessary">Не обязательно</span>
      </div>
      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="date" *ngIf="formControls.date.value">Дата рождения</label>
        <input
          type="text"
          [class.error-field]="submitted && formControls.date.errors"
          [textMask]="{ mask: dateMask }"
          name="date"
          placeholder="Дата рождения"
          data-name="date"
          id="date"
          formControlName="date"
          autocomplete="off"
        />
      </div>

      <div class="pupil-mobile-edit-profile-body-form-control" *ngIf="formControls.className.value">
        <label class="prf-form-label" for="className">Класс</label>
        <input
          readonly
          type="text"
          name="className"
          placeholder="Класс"
          data-name="className"
          id="className"
          formControlName="className"
          autocomplete="off"
        />
      </div>

      <div class="profile-data-row gender-choice">
        <label for="gender" class="login-label-gender">Пол</label>
        <prf-pupil-gender-toggle formControlName="gender" id="gender"></prf-pupil-gender-toggle>
      </div>
      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="Phone" *ngIf="formControls.phone.value">{{ 'SHARED.FORM.PHONE' | translate }}</label>
        <input
          type="text"
          autocomplete="off"
          prfPhoneMask
          [control]="formControls.phone"
          [htmlInputElement]="phone"
          [class.error-field]="submitted && formControls.phone.errors"
          name="Phone"
          data-name="Phone"
          id="Phone"
          formControlName="phone"
          [placeholder]="'SHARED.FORM.PHONE' | translate"
          (focusout)="checkPhone()"
          #phone
        />
        <ng-container *ngIf="submitted">
          <mat-error *ngIf="formControls.phone.errors && formControls.phone.errors?.pattern"> Номер телефона введен неверно</mat-error>
          <mat-error *ngIf="duplicateUserName">
            {{ 'REGISTRATION_PUPIL_KZ.FORM_ALERT.EMAIL_EXIST' | translate }}
          </mat-error>
          <mat-error *ngIf="!!formControls.phone.errors?.isUsed"> Телефон зарегистрирован. </mat-error>
        </ng-container>
      </div>

      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="Email" *ngIf="formControls.email.value">Email</label>
        <input
          type="text"
          [class.error-field]="submitted && formControls.email.errors"
          name="Email"
          data-name="Email"
          formControlName="email"
          id="Email"
          autocomplete="off"
          placeholder="Email"
          (focusout)="checkFormatEmail($event)"
        />
        <span class="pupil-mobile-edit-profile-body-form-control-not-necessary">Не обязательно</span>
        <ng-container *ngIf="submitted">
          <mat-error *ngIf="!checkEmail">
            {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
          </mat-error>
        </ng-container>
      </div>
      <div class="pupil-mobile-edit-profile-body-form-control">
        <label class="prf-form-label" for="firstName" *ngIf="formControls.password.value">Пароль</label>
        <input
          type="password"
          [class.error-field]="submitted && formControls.password.errors"
          name="password"
          data-name="password"
          id="password"
          formControlName="password"
          placeholder="Пароль"
          autocomplete="off"
        />
        <div class="pupil-mobile-edit-profile-body-form-control-img" (click)="changePasswordEditor(true)"></div>
        <div class="pupil-mobile-edit-profile-body-form-control-changed" *ngIf="changedPasswordDate">
          Изменен {{ changedPasswordDate | date : 'dd.MM.yyyy HH:mm' }}
        </div>
      </div>
      <div class="div-space-2"></div>
      <div class="pupil-mobile-edit-profile-body-form-control" *ngIf="!changedPassword">
        <button type="button" (click)="logout()">Выйти</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #avatarEdit>
  <prf-pupil-mobile-edit-user-avatar (saveAvatar)="closeAvatarEdit()" (return)="closeAvatarEdit()"></prf-pupil-mobile-edit-user-avatar>
</ng-template>
<ng-template #passwordEdit>
  <div *ngIf="changedPassword" class="edit-profile-password">
    <div class="edit-profile-password-header">
      <div class="edit-profile-password-header-title">Смена пароля</div>
      <div class="edit-profile-password-header-icon" (click)="changePasswordEditor(false)">
        <img src="./profilum-assets/images/common/close.svg" [alt]="'SHARED.CLOSE_WINDOW' | translate" />
      </div>
    </div>
    <ng-container *ngIf="!passwordSaving; else savingPasswordIcon">
      <form
        class="pupil-mobile-edit-profile-body-form"
        id="password-form"
        name="password-form"
        [formGroup]="passwordForm"
        autocomplete="off"
      >
        <div class="pupil-mobile-edit-profile-body-form-control">
          <label class="prf-form-label" for="firstName" *ngIf="formPasswordControls.oldPassword.value" [class.error-label]="errorOldPass"
            >Старый пароль</label
          >
          <input
            type="password"
            [class.error-field]="submittedPassForm && (errorOldPass || formPasswordControls.newPassword.errors)"
            name="oldPassword"
            data-name="oldPassword"
            id="oldPassword"
            formControlName="oldPassword"
            placeholder="Старый пароль"
            autocomplete="off"
            (focus)="clearPassErrors('oldPassword')"
          />
          <div class="edit-profile-password-eye" (click)="toggleMaskOld()" *ngIf="passwordForm.controls['oldPassword'].value">
            <ng-container *ngIf="isMaskedPasswordOld">
              <img src="./profilum-assets/images/common/eye.svg" alt="Пароль виден" />
            </ng-container>
            <ng-container *ngIf="!isMaskedPasswordOld">
              <img src="./profilum-assets/images/common/close-eye.svg" alt="Пароль скрыт" />
            </ng-container>
          </div>
          <mat-error *ngIf="errorOldPass" class="edit-profile-password-error">
            {{ errorOldPass }}
          </mat-error>
        </div>
        <div class="pupil-mobile-edit-profile-body-form-control">
          <label class="prf-form-label" for="firstName" *ngIf="formPasswordControls.newPassword.value">Новый пароль</label>
          <input
            type="password"
            [class.error-field]="submittedPassForm && (errorPass || formPasswordControls.newPassword.errors || focusOutPasswordErrors)"
            name="newPassword"
            data-name="newPassword"
            id="newPassword"
            formControlName="newPassword"
            placeholder="Новый пароль"
            autocomplete="off"
            (focus)="clearPassErrors('newPassword')"
            (input)="testNewPassword()"
            (focusout)="focusOutErrorChecking()"
          />
          <div class="edit-profile-password-eye" (click)="toggleMask()" *ngIf="passwordForm.controls['newPassword'].value">
            <ng-container *ngIf="isMaskedPassword">
              <img src="./profilum-assets/images/common/eye.svg" alt="Пароль виден" />
            </ng-container>
            <ng-container *ngIf="!isMaskedPassword">
              <img src="./profilum-assets/images/common/close-eye.svg" alt="Пароль скрыт" />
            </ng-container>
          </div>

          <ul class="prf-form-password-validators">
            <li class="prf-form-password-validators-message" [class.prf-form-password-validators-message_checked]="!charactersError">
              от 6 символов
            </li>
            <li class="prf-form-password-validators-message" [class.prf-form-password-validators-message_checked]="!letterError">
              строчные и заглавные латинские буквы
            </li>
            <li class="prf-form-password-validators-message" [class.prf-form-password-validators-message_checked]="!numberError">
              1 цифра
            </li>
          </ul>
          <mat-error *ngIf="errorPass" class="edit-profile-password-error">
            {{ errorPass }}
          </mat-error>
        </div>
      </form>
    </ng-container>
  </div>
</ng-template>

<ng-template #savingPasswordIcon>
  <div class="animation-container">
    <prf-circle-icon></prf-circle-icon>
    <div class="animation-container__arrow">
      <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.3875 0.625L10.625 20L3.6125 13.125C2.7625 12.2917 1.4875 12.2917 0.6375 13.125C-0.2125 13.9583 -0.2125 15.2083 0.6375 16.0417L9.1375 24.375C9.5625 24.7917 9.9875 25 10.625 25C11.2625 25 11.6875 24.7917 12.1125 24.375L33.3625 3.54167C34.2125 2.70833 34.2125 1.45833 33.3625 0.625C32.5125 -0.208333 31.2375 -0.208333 30.3875 0.625Z"
          fill="#0CAB72"
        />
      </svg>
    </div>
  </div>
</ng-template>
