import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { JsonFormControls } from '../../../interfaces/form.interface';

@Component({
  selector: 'prf-input-component',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FormsModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends UnsubscribeComponent {
  public focused: boolean = false;

  @Input() public controlConfig: JsonFormControls;
  @Input() public formControlManager: FormControl;
  @Input() public errors: ValidationErrors;

  @Output() public iconClickEmitter: EventEmitter<JsonFormControls> = new EventEmitter<JsonFormControls>();

  public onBlur(): void {
    this.focused = false;
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onIconClicked(): void {
    this.iconClickEmitter.emit(this.controlConfig);
  }

  public get isValid(): boolean {
    return this.formControlManager.valid;
  }

  public get isIconVisible(): boolean {
    return this.controlConfig?.icon && (this.controlConfig.icon === 'clear' ? this.formControlManager?.value : true);
  }
}
