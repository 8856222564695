<form class="prf-form" [formGroup]="form" [ngClass]="additionalStyleClasses" (ngSubmit)="onSubmit()">
  <div class="form-wrapper">
    @for (control of jsonFormData()?.controls; track control.name; let i = $index) {
      <div class="field-wrapper" [ngClass]="'field-wrapper-' + (i + 1)">
        <prf-input-component
          *ngIf="['text', 'email', 'phone', 'password'].includes(control.type)"
          [formControlManager]="formControlGetter(control.name)"
          [controlConfig]="control"
          [errors]="formControlGetter(control.name).errors"
          (iconClickEmitter)="handleIconClick($event)"
        />

        <prf-toggle
          *ngIf="['toggle'].includes(control.type)"
          [formControlManager]="formControlGetter(control.name)"
          [controlConfig]="control"
        />

        <prf-datepicker
          *ngIf="['date'].includes(control.type)"
          [formControlManager]="formControlGetter(control.name)"
          [control]="control"
        />

        <prf-dropdown
          *ngIf="['dropdown', 'dropdown-search'].includes(control.type)"
          [control]="control"
          [formControlManager]="formControlGetter(control.name)"
          [isSearch]="['dropdown-search'].includes(control.type)"
        />

        <prf-dropdown-custom
          *ngIf="['dropdown-custom'].includes(control.type)"
          [control]="control"
          [formControlManager]="formControlGetter(control.name)"
          [isSearch]="['dropdown-search'].includes(control.type)"
          (submitOpen)="onOpen()"
          (submitClose)="onClose()"
        />

        <prf-checkbox-component
          *ngIf="['checkbox'].includes(control.type)"
          [controlConfig]="control"
          [formControlManager]="formControlGetter(control.name)"
        />

        <!--TODO для вывода ошибок для поля не в фокусе придётся перенести компонент ошибок внутрь контрола (нет возможности следить за фокусом без "грязи")-->
        @if (control?.validators) {
          <prf-form-errors [errors]="formControlGetter(control.name)?.errors" />
        }
      </div>
    }

    @if (jsonFormData()?.customFormValidators) {
      @if (formErrors?.mismatch) {
        <prf-form-errors [errors]="{ mismatch: true }" />
      }
    }
  </div>

  <!--    <textarea-->
  <!--      *ngIf="control.type === 'textarea'"-->
  <!--      [formControlName]="control.name"-->
  <!--      [value]="control.value"-->
  <!--    ></textarea>-->
  <!--    <checkbox-->
  <!--      *ngIf="control.type === 'checkbox'"-->
  <!--      [formControlName]="control.name"-->
  <!--      [checked]="control.value"-->
  <!--    ></checkbox>-->
  <!--    <toggle-->
  <!--      *ngIf="control.type === 'toggle'"-->
  <!--      [formControlName]="control.name"-->
  <!--      [checked]="control.value"-->
  <!--    ></toggle>-->
  <!--    <range-->
  <!--      *ngIf="control.type === 'range'"-->
  <!--      [min]="control.options.min"-->
  <!--      [max]="control.options.max"-->
  <!--      [formControlName]="control.name"-->
  <!--    >-->
  <!--      <icon-->
  <!--        size="small"-->
  <!--        slot="start"-->
  <!--        [name]="control.options.icon"-->
  <!--      ></icon>-->
  <!--      <ion-icon slot="end" [name]="control.options.icon"></ion-icon>-->
  <!--    </range>-->
  @if (isButtonsVisible) {
    <div class="buttons">
      <prf-button
        *ngIf="cancelButton"
        [isFullWidth]="true"
        [buttonTitle]="cancelButton"
        [type]="ButtonType.Tertiary"
        [disabled]="isCancelButtonDisabled"
        (emitClick)="onCancelButtonClick()"
      />
      <prf-button
        [isFullWidth]="true"
        [buttonTitle]="acceptButton || defaultButtonText"
        [type]="ButtonType.Primary"
        [disabled]="!form.valid"
        (emitClick)="onSubmit()"
      />
    </div>
  }
</form>
