import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ApiFavoritesService,
  ApiLocationsService,
  ApiPlayerService,
  ApiSchoolsService,
  B2gSaasService,
  EmptyGuid,
  FavoritesDataHandlerService,
  IData,
  ISchool,
  ITestInfo,
  IUserInfo,
  IUserProfileInfo,
  RamStorageService,
  StorageKeys,
  Storages,
  UserActionsService,
  UserDataHandlerService,
  WebStorageService,
  YmItems,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { TranslateService } from '@ngx-translate/core';

import { IAssigningCourseData } from '../../common-components/class-courses/interfaces/class-courses.interfaces';
import { RootScopeService } from '../../services/root-scope.service';
import { MenuItems } from '../../enums/menu.enums';
import { DropdownItemType } from '../../../ui-kit/components/dropdown/types';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';
import { PageHeaderService } from './page-header.service';
import { TeacherPanelService } from 'app/pages/control-panel/teacher/teacher-panel.service';
import { MenuService } from '../menus/menu/menu.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';

@Component({
  selector: 'prf-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild('headerMenu') headerMenu;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('nav') nav: ElementRef;
  @Input() childTested: boolean = false;
  @Input() schoolTitle: string;
  @Input() isWelcome: boolean = false;
  @Input() backButtonName: string = '';
  @Input() backButtonUrl: string = '';
  @Input() isTeacherDashboard: boolean = false;
  @Input() editButton: boolean = false;
  @Input() title: string = '';
  @Input() buttonToTest: boolean = false;
  @Input() directorClassMessageButton: boolean = false;
  @Input() backButton: boolean = false;
  @Input() showClassDropdown: boolean = true;
  @Input() isOnlyDisabledDropdownVariant: boolean = false;
  @Output() adminLevel = new EventEmitter<any>();
  @Output() backAction = new EventEmitter<boolean>();
  public isSchoolStaff: boolean = false;
  public isMunicipalityAdmin: boolean = false;
  public mainTeacherPage: boolean = false;
  public userInfo: IUserInfo;
  public dataFetched: boolean = false;
  public userFavorites: any = [];
  public classArray: IAssigningCourseData[];
  public selectedClass: IAssigningCourseData;
  public dropdownClassesOptions: DropdownItemType[] = [];
  public dropdownSelectedOption: DropdownItemType = null;
  public dictionary: DictionaryType;
  private showAddPupils: boolean;
  private regionId: string;
  private cityId: string;
  private isMOStend: boolean = false;

  constructor(
    private router: Router,
    private apiPlayerService: ApiPlayerService,
    private pageHeaderService: PageHeaderService,
    private apiSchoolsService: ApiSchoolsService,
    private apiLocationsService: ApiLocationsService,
    private teacherService: TeacherPanelService,
    private apiFavoritesService: ApiFavoritesService,
    private menuService: MenuService,
    private b2gSaasService: B2gSaasService,
    private translateService: TranslateService,
    private webStorageService: WebStorageService,
    private rootScopeService: RootScopeService,
    private ramStorageService: RamStorageService,
    private userActionsService: UserActionsService,
    private userDataHandlerService: UserDataHandlerService,
    private favoritesDataHandlerService: FavoritesDataHandlerService,
  ) {
    super();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.translateService
      .get('SHARED.GENERAL')
      .pipe(take(1))
      .subscribe(translation => {
        if (!this.title) {
          this.title = translation;
        }
      });
    this.dictionary = rootScopeService.getDictionary();
    this.isMOStend = location.origin.includes('mosreg');
    this.userActionsService.setInitializationTime([YmItems.T_SelectClass]);
  }

  public ngOnInit(): void {
    this.mainTeacherPage = location.pathname === '/teacher';

    if (this.userInfo.role) {
      this.userFavorites = this.favoritesDataHandlerService.getFavoriteClasses().getValue();

      this.b2gSaasService
        .getUserInfo()
        .pipe(
          switchMap(() => {
            if (this.userInfo?.schoolId && this.userInfo?.schoolId != EmptyGuid) {
              return this.apiSchoolsService.getSchoolById(this.userInfo.schoolId);
            } else {
              return of(null);
            }
          }),
          switchMap((response: ISchool) => {
            if (response && response?.cityId) {
              this.cityId = response.cityId;

              return this.apiLocationsService.getCity(response.cityId);
            } else {
              return of(null);
            }
          }),
          switchMap(response => {
            if (!response?.city) {
              return of(null);
            }

            this.regionId = response.city.regionId;
            return this.apiPlayerService.regionTestInfo(this.regionId);
          }),
          tap((data: ITestInfo) => {
            this.webStorageService.set(StorageKeys.IsPilot, data?.isPilot);
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe(() => (this.dataFetched = true));

      this.teacherService
        .getEditPupils()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => (this.showAddPupils = data.editPupils));

      this.isSchoolStaff = this.userInfo.role === 'director' || this.userInfo.role === 'schooladmin' || this.userInfo.role === 'teacher';
      this.isMunicipalityAdmin = AdminAccessLevel.MUNICIPALITY === this.webStorageService.get(StorageKeys.AdminLevel);

      if (this.userInfo.role === 'teacher') {
        this.getClasses();
      }
    }
  }

  public getClasses(): void {
    this.rootScopeService.classesArray = this.webStorageService.get(StorageKeys.ClassesArray);
    this.rootScopeService.classesList
      .pipe(
        switchMap((classes: IAssigningCourseData[]) => {
          if (classes) {
            this.classArray = classes;
            this.dropdownClassesOptions = this.getDropdownOptions(classes);
          }

          return this.rootScopeService.currentCLass;
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe((selectedClass: IAssigningCourseData | null) => {
        if (selectedClass === null) {
          this.selectedClass = this.classArray[0];
          this.rootScopeService.currentClassInfo = this.classArray[0];
        }

        this.selectedClass = selectedClass;
        this.dropdownSelectedOption = {
          name: `${this.selectedClass.schoolClass.number} «${this.selectedClass.schoolClass.letter}»`,
          data: selectedClass,
        };
      });
  }

  public selectClass(clickedClass: IAssigningCourseData): void {
    if (clickedClass.schoolClass.id === this.selectedClass.schoolClass.id) return;

    this.ramStorageService
      .get(StorageKeys.SelectedMenuItem, Storages.Local)
      .pipe(this.unsubscribeOperator)
      .subscribe((selectedMenuItem: number) => {
        let currentSiteSection: string = '';

        const uaParams: IData = {
          CurrentSiteSection: '',
          OldChosenClassId: this.selectedClass.schoolClass.id,
          NewChosenClassId: clickedClass.schoolClass.id,
        };

        const ymParams: IData = {
          event_label: { section_of_the_website: '' },
        };

        switch (selectedMenuItem) {
          case MenuItems.Lessons:
            currentSiteSection = 'lessons';
            break;

          case MenuItems.Pupils:
            currentSiteSection = 'pupils';
            break;

          case MenuItems.Results:
            currentSiteSection = 'results';
            break;

          default:
            break;
        }

        uaParams.CurrentSiteSection = currentSiteSection;
        ymParams.event_label.section_of_the_website = currentSiteSection;

        if (currentSiteSection) {
          this.userActionsService.log(YmItems.T_SelectClass, uaParams, ymParams);
        }
      });

    this.selectedClass = clickedClass;
    this.dropdownSelectedOption = {
      name: `${this.selectedClass.schoolClass.number} «${this.selectedClass.schoolClass.letter}»`,
      data: clickedClass,
    };
    this.rootScopeService.currentClassInfo = clickedClass;
  }

  public getDropdownOptions(classes: IAssigningCourseData[]): DropdownItemType[] {
    const result = [];
    if (Array.isArray(classes) && classes.length) {
      classes.forEach((classItem: IAssigningCourseData) => {
        if (classItem.schoolClass?.number && classItem.schoolClass?.letter) {
          result.push({
            name: `${classItem.schoolClass.number} «${classItem.schoolClass.letter}»`,
            data: classItem,
          });
        }
      });
    }

    return result;
  }

  public sendParentMessage(): void {
    this.pageHeaderService.sendParentMessage('true');
  }

  public showMobileMenu(): void {
    this.menuService.showMobileMenu(true);
  }

  public isOpenProfile(): boolean {
    return this.userInfo.role == 'pupil' || this.userInfo.role == 'parent' || this.userInfo.role == 'teacher';
  }

  public checkNoticeAllow(): boolean {
    if (!this.childTested) {
      return false;
    }

    return this.userInfo.role == 'pupil' || this.userInfo.role == 'parent';
  }

  public showEditClass(): void {
    this.pageHeaderService.closeProfile();
    if (this.userInfo.role === 'teacher') {
      this.teacherService.showAddPupils();
    }
  }

  public isBackButtonWithUrlShow(): boolean {
    return this.backButtonName !== '' && this.backButtonUrl !== '';
  }

  public isEditButtonShow(): boolean {
    return this.editButton && this.userInfo.role === 'teacher';
  }

  public stepBack(): void {
    this.backAction.emit(true);
  }
}
