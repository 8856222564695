<div class="field">
  <label [for]="control().name">{{ control().label }}</label>
  <div class="wrapper">
    <ng-select
      class="custom"
      appendTo="body"
      #dropdownRef
      dropdownPosition="bottom"
      notFoundText="Ничего не найдено"
      [formControl]="formControlManager()"
      [id]="control().name"
      [searchable]="false"
      [clearable]="false"
      (clear)="dropdownRef.close()"
      (open)="onOpen()"
      (close)="onClose()"
    >
      @for (option of control().dropdownOptions; track option.value) {
        <ng-option class="custom" [value]="option.value">
          {{ option.label }}
        </ng-option>
      }
    </ng-select>
  </div>
</div>
