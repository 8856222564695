import { ChangeDetectionStrategy, Component, EventEmitter, Input, input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownJsonFormControl } from '../dropdown/interfaces/dropdown.interfaces';

@Component({
  selector: 'prf-dropdown-custom',
  standalone: true,
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
  templateUrl: './dropdown-custom.component.html',
  styleUrl: './dropdown-custom.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownCustomComponent {
  public control = input<DropdownJsonFormControl>();
  public formControlManager = input<FormControl>();

  @Input() public isSearch: boolean = false;
  @Output() public submitOpen = new EventEmitter<any>();
  @Output() public submitClose = new EventEmitter<any>();

  public onOpen = () => {
    this.submitOpen.emit();
  };

  public onClose = () => {
    this.submitClose.emit();
  };
}
