import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ResultsService } from '../../../results/results.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  ApiPlayerService,
  AppSettingsService,
  B2gSaasService,
  EUserTags,
  IUserInfo,
  IUserProfileInfo,
  StorageKeys,
  UserDataHandlerService,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-profession-cards',
  templateUrl: './profession-cards.component.html',
  styleUrls: ['./profession-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfessionCardsComponent extends UnsubscribeComponent implements OnInit {
  public loaded: boolean = false;
  public userInfo: IUserInfo;
  public testSessionId: string = '';
  public sessionStatus: string = '';
  public tabName: string = 'catalog'; // [catalog, recommendations]
  public testResults: any;
  public selectLanguage: boolean = false;
  isVGuser: boolean = false;

  constructor(
    private meta: Meta,
    private resultsService: ResultsService,
    private b2gSaasService: B2gSaasService,
    private apiPlayerService: ApiPlayerService,
    private activatedRoute: ActivatedRoute,
    private webStorageService: WebStorageService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    super();
    this.userInfo = this.userDataHandlerService.getUserInfo();
    this.testSessionId = this.webStorageService.get(StorageKeys.TestSessionId);
    this.sessionStatus = this.webStorageService.get(StorageKeys.SessionStatus);
    this.isVGuser = this.webStorageService.get(StorageKeys.Tag) === EUserTags[EUserTags.VorobieviGori].toString();
    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguage =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == this.webStorageService.get(StorageKeys.RegionId);

    this.activatedRoute.fragment.subscribe(param => {
      if (param === 'recommendations') {
        this.tabName = 'recommendations';
      }
    });
  }

  public ngOnInit(): void {
    this.loadData()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => (this.loaded = true));
  }

  private loadData(): Observable<any> {
    if (this.sessionStatus === 'Success' && this.userInfo.role === 'pupil') {
      const sessionIds = [this.testSessionId];

      const getResultsObject = {
        regionId: this.userInfo.regionId,
        municipalityId: this.userInfo.municipalityId,
        sessionIds: sessionIds,
        role: this.userInfo.role,
      };

      let currentObservable$: Observable<any> = of(null);
      if (this.selectLanguage) {
        currentObservable$ = this.apiPlayerService.createSession(this.testSessionId).pipe(
          tap(session => {
            Object.assign(getResultsObject, { language: session.language });
          }),
        );
      }
      return currentObservable$.pipe(
        switchMap(() => {
          return this.b2gSaasService.getResultPage(getResultsObject).pipe(
            tap(results => {
              if (results.results.length > 0) {
                this.testResults = results.results;
              }
            }),
          );
        }),
      );
    } else {
      return of(null);
    }
  }
}
