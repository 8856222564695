import { Component, Input } from '@angular/core';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import {
  ApiPlayerService,
  ApiTemporaryService,
  Helper,
  IPassedLessons,
  ITestInfo,
  StorageKeys,
  WebStorageService,
} from '@profilum-library';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IAnswerPercents } from '@profilum-api-services/bff-project-director/bff-project-director.interface';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';

import { IResultsData } from 'app/pages/results/results.interface';
import { CAPABILITY_MOCK, CAREER_LITERACY_MOCK, INTERESTS_MOCK, PROFIL_CLASS_MOCK } from './diagnostic-results.mock';
import { TeachersJournalService } from 'app/shared/dashboard/career-education/teachers-journal/teachers-journal.service';
import {
  ILessonResultsCount,
  IMappedLessonData,
  IMappedLessonWithCourseData,
} from 'app/shared/dashboard/career-education/interfaces/career-education.interfaces';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { IDiagnosticResults } from './diagnostic-results.interface';
import { ChartType } from 'chart.js';
import { RootScopeService } from '../../services/root-scope.service';
import { DictionaryType } from '../../../landing/base/landing-base.dictionary';

@Component({
  selector: 'prf-diagnostic-results',
  templateUrl: './diagnostic-results.component.html',
  styleUrls: ['./diagnostic-results.component.scss'],
})
export class DiagnosticResultsComponent extends UnsubscribeComponent {
  public resultsData: IDiagnosticResults[];
  public declOfNum = Helper.declOfNum;
  public dictionary: DictionaryType;
  public isKruzhkiVariant: boolean = false;

  private lessonResultsCount: ILessonResultsCount;
  private totalPupilCount: number;
  private careerLiteracyLessonMaterialId: string;
  private interestsLessonMaterialId: string;
  private capabilitiesLessonMaterialId: string;
  private passedDiagnostic: Record<string, boolean> = {};
  private interestsScreeningTestId: string;
  private aptitudeScreeningTestId: string;

  @Input() set lessons(lessons: IMappedLessonWithCourseData) {
    if (lessons) {
      this.overlayService.show();
      this.teachersJournalService.lessonResultsCount$
        .pipe(
          switchMap(lessonResultsCount => {
            this.lessonResultsCount = lessonResultsCount;
            return this.apiTemporaryService.getSchoolClassPercentsPageForCG(lessons.currentClass.id);
          }),
          take(1),
          takeUntil(this.unsubscribe),
        )
        .subscribe(result => {
          this.totalPupilCount = lessons.mappedLessonData.length;
          this.setLessonMaterialId(lessons.mappedLessonData);
          this.setPassedDiagnostic(lessons.course.passedLessons);
          this.setResults(result);
          this.overlayService.hide();
        });
    }
  }

  constructor(
    private webStorageService: WebStorageService,
    private apiPlayerService: ApiPlayerService,
    private apiTemporaryService: ApiTemporaryService,
    private overlayService: OverlayBusyService,
    private teachersJournalService: TeachersJournalService,
    private rootScopeService: RootScopeService,
  ) {
    super();
    this.dictionary = this.rootScopeService.getDictionary();
    this.isKruzhkiVariant = location.origin.includes('kruzhki');
    if (this.isKruzhkiVariant) {
      this.fixTextsForKruzhki();
    }
    this.getRegionTestInfo();
  }

  private getRegionTestInfo(): void {
    const regionId: string = this.webStorageService.get(StorageKeys.RegionId);
    this.apiPlayerService
      .regionTestInfo(regionId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (el: ITestInfo) => {
          this.interestsScreeningTestId = el.interestsScreeningTestId;
          this.aptitudeScreeningTestId = el.aptitudeScreeningTestId;
        },
      });
  }

  private setLessonMaterialId(lessons: IMappedLessonData[]): void {
    lessons[0]?.lessonData.forEach(lessonData => {
      const testId = lessonData?.lesson?.tasks[0]?.screeningTestTask?.screeningTestId;
      if (testId === TESTS_VARIANTS_IDS.careerLiteracyTest) {
        this.careerLiteracyLessonMaterialId = lessonData.lesson?.lessonMaterialId;
      } else if (testId === TESTS_VARIANTS_IDS.interestsTest || testId === this.interestsScreeningTestId) {
        this.interestsLessonMaterialId = lessonData.lesson?.lessonMaterialId;
      } else if (testId === TESTS_VARIANTS_IDS.capabilitiesTest || testId === this.aptitudeScreeningTestId) {
        this.capabilitiesLessonMaterialId = lessonData.lesson?.lessonMaterialId;
      }
    });
  }

  private setPassedDiagnostic(passedLessons: IPassedLessons[]) {
    passedLessons.forEach(lesson => {
      switch (lesson.id) {
        case this.careerLiteracyLessonMaterialId:
          this.passedDiagnostic[this.careerLiteracyLessonMaterialId] = lesson.isPassed;
          break;
        case this.interestsLessonMaterialId:
          this.passedDiagnostic[this.interestsLessonMaterialId] = lesson.isPassed;
          break;
        case this.capabilitiesLessonMaterialId:
          this.passedDiagnostic[this.capabilitiesLessonMaterialId] = lesson.isPassed;
          break;
        default:
          break;
      }
    });
  }

  private setResults(result): void {
    this.resultsData = [];
    let resultsData;
    if (result['data'] || result['aggregates'].length) {
      resultsData = result['resultsData'] ? result['resultsData'] : { results: result['aggregates'] };
    }
    if (this.passedDiagnostic[this.careerLiteracyLessonMaterialId]) {
      this.resultsData.push(this.combineResultsData('doughnut', 'Карьерная грамотность', 'CareerLiteracy', true));
      this.resultsData.forEach(data => {
        if (data.data.length === 0) {
          data.data = CAREER_LITERACY_MOCK.data;
        }
      });
    } else {
      this.resultsData.push(CAREER_LITERACY_MOCK);
    }

    if (this.passedDiagnostic[this.interestsLessonMaterialId]) {
      this.resultsData.push(this.combineResultsData('doughnut', 'Интересы', 'TalentKG', false, resultsData));
      this.resultsData.forEach(data => {
        if (data.data.length === 0) {
          data.data = INTERESTS_MOCK.data;
        }
      });
    } else {
      this.resultsData.push(INTERESTS_MOCK);
    }

    if (this.passedDiagnostic[this.capabilitiesLessonMaterialId]) {
      this.resultsData = this.resultsData.concat([
        this.combineResultsData('doughnut', 'Способности', 'AptitudeAttainment', false, resultsData),
        this.combineResultsData('bar', `Выбор профильных ${this.dictionary.ClassesGenitive}`, 'ProfilClass', false, resultsData),
      ]);
      this.resultsData.forEach(data => {
        if (data.data.length === 0 && data.titleText === `Выбор профильных ${this.dictionary.ClassesGenitive}`) {
          data.data = PROFIL_CLASS_MOCK.data;
        }
        if (data.data.length === 0 && data.titleText === 'Способности') {
          data.data = CAPABILITY_MOCK.data;
        }
      });
    } else {
      this.resultsData = this.resultsData.concat([CAPABILITY_MOCK, PROFIL_CLASS_MOCK]);
    }
  }

  private combineResultsData(
    chartType: ChartType,
    titleText: string,
    aggregate: string,
    hideToggle: boolean = false,
    resultsData?: IResultsData,
  ): IDiagnosticResults {
    const diagnosticResult = {
      chartType,
      titleText,
      data: resultsData ? this.getObjectTypeAnswerPercents(resultsData, aggregate) : [],
      hideToggle,
    };

    resultsData ? this.getTotalCount(diagnosticResult, aggregate, resultsData) : this.getTotalCount(diagnosticResult, aggregate);

    return diagnosticResult;
  }

  private getObjectTypeAnswerPercents(resultsData: IResultsData, objectType: string): IAnswerPercents[] {
    const results: IAnswerPercents[] = [];
    const objectTypeResults = resultsData.results.find(d => d.objectType === objectType);
    if (objectTypeResults) {
      objectTypeResults.objects.forEach(ob =>
        results.push({ name: ob.name, count: ob.count, percents: Math.round(ob.percents), description: ob.description }),
      );
    }
    return results;
  }

  private getTotalCount(diagnosticResult: IDiagnosticResults, objectType: string, resultsData?: IResultsData): void {
    let isPassed: boolean;
    let result = 0;
    const objectTypeResults = resultsData?.results.find(d => d.objectType === objectType);

    if (objectType === 'CareerLiteracy') {
      isPassed = this.passedDiagnostic[this.careerLiteracyLessonMaterialId];
      result = this.lessonResultsCount[this.careerLiteracyLessonMaterialId]?.[0];
    } else if (objectType === 'TalentKG') {
      isPassed = this.passedDiagnostic[this.interestsLessonMaterialId];
      result = this.lessonResultsCount[this.interestsLessonMaterialId]?.[0];
    } else if (objectType === 'AptitudeAttainment') {
      isPassed = this.passedDiagnostic[this.capabilitiesLessonMaterialId];
      result = this.lessonResultsCount[this.capabilitiesLessonMaterialId]?.[0];
    } else {
      isPassed = this.passedDiagnostic[this.capabilitiesLessonMaterialId];
      objectTypeResults?.objects?.forEach(ob => (result += ob.count));
    }

    if (!isPassed) {
      diagnosticResult.descriptionText = 'Задание не выдано';
      diagnosticResult.status = 'not-passed';
    } else if (result === 0) {
      diagnosticResult.descriptionText = 'Выдано. Результаты - после прохождения и подсчета статистики.';
      diagnosticResult.status = 'passed-not-started';
    } else if (result < this.totalPupilCount) {
      diagnosticResult.descriptionText = this.getDescriptionText(result);
      diagnosticResult.status = 'passed-started';
    } else {
      diagnosticResult.descriptionText = this.getDescriptionText(result);
      diagnosticResult.status = 'passed-completed';
    }
  }

  private getDescriptionText(result: number): string {
    return `${this.declOfNum(result, ['Прошел ', 'Прошли ', 'Прошли '])}
      ${result || 0} из ${this.totalPupilCount}
      ${this.declOfNum(this.totalPupilCount, [
        ` ${this.dictionary.Pupil}`,
        ` ${this.dictionary.PupilGenitive}`,
        ` ${this.dictionary.PupilsGenitive}`,
      ])}`;
  }

  private fixTextsForKruzhki(): void {
    PROFIL_CLASS_MOCK.titleText = `Выбор профильных ${this.dictionary.ClassesGenitive}`;
    PROFIL_CLASS_MOCK.data.forEach((item: any) => {
      item.name = item.name.replaceAll(/ий|ый/gi, 'ая').replace('класс', 'группа');
    });
  }
}
